/* stylelint-disable declaration-no-important */

.GeelyFAQ {
    padding: 0 16px;

    .FAQ {
        &-AccordionWrapper {
            max-width: 1240px;
            border: none;
            border-radius: 8px;
            overflow: hidden;
        }

        &-AccordionHeading {
            min-height: initial;
        }

        &-HeadingWrapper {
            display: initial;
            margin: 0 0 24px;

            @include desktop {
                margin: 0 0 48px;
            }
        }

        &-Heading {
            font-weight: 500;
            text-transform: uppercase;
            line-height: 34px;
            font-size: 28px;
            margin: 0 0 12px;

            @include desktop {
                font-size: 36px;
                line-height: 48px;
            }
        }

        &-Description {
            font-size: 16px;
            line-height: 24px;
            color: var(--default-primary-silver-color);
            margin: 0;
        }

        &-AccordionContent {
            background-color: var(--default-primary-light-grey-color);
            line-height: 25px !important;
            color: var(--default-primary-silver-color) !important;

            @include mobile {
                font-size: 14px !important;
            }

            ul, ol {
                margin-block-start: 6px;

                li {
                    list-style-type: unset !important;
                    color: var(--default-primary-silver-color) !important;

                    @include mobile {
                        font-size: 14px !important;
                    }
                }
            }
        }

        &-Hide {
            font-weight: 500;

            @include desktop {
                font-size: 18px;
            }
        }

        &-Accordion {
            .AccordionTabs {
                &-TabName {
                    padding-inline: 24px 64px;
                    display: block;
                    width: 100%;
                    border-radius: 0;
                    margin: 0;

                    &::after {
                        inset-block-start: calc(50% - 12px);
                        transform: none;
                    }

                    &_isSelected {
                        background-color: var(--default-primary-light-grey-color);
                        border: none;

                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &-AccordionText {
            color: var(--default-primary-silver-color);
            font-size: 14px;
            line-height: 25px;
            
            @include desktop {
                font-size: 16px;
            }
        }
    }
}
