/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --my-vehicles-pod-img-width: 42%;
}

.MyVehicles-Pod {
    &-InfoValue {
        font-weight: 500;

        &_isDefault,
        &_discountAmount,
        &_reservationAmount {
            font-weight: 400;
        }

        &_isBold {
            font-weight: 500;
        }
    }

    &-DealerMessage {
        font-weight: 400;
    }

    &-RightSide {
        @include desktop {
            flex-grow: unset;
            width: calc(100% - var(--my-vehicles-pod-img-width));
        }
    }

    &-ImageWrapper {
        @include desktop {
            width: var(--my-vehicles-pod-img-width);
        }
    }

    &-OptionsWrapper {
        @include desktop {
            display: flex;
            width: 100%;

            .MyVehicles-Pod {
                &-InfoName {
                    width: unset;
                    flex-grow: unset;
                }
            }
        }

        @include mobile {
            .MyVehicles-Pod {
                &-InfoValue {
                    padding-inline-start: 0;
                }
            }
        }

        .MyVehicles-Pod {
            &-InfoValue {
                max-width: unset;
                width: 100%;
            }
        }
    }

    &-Actions {
        display: flex;
        flex-direction: column;
        min-width: fit-content;
        align-items: center;
        gap: 16px;

        @include desktop {
            flex-direction: row;
            padding-inline-end: 40px;
        }

        .Button {
            min-width: fit-content;
            line-height: 20px;

            &_isHollow {
                line-height: 18px;
                color: var(--default-primary-black-color);
            }
        }
    }

    &-GrandTotalValue {
        font-weight: 500;
    }
}
