/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --expandable-content-background-color: var(--background-color);
    --expandable-content-stroke-color: var(--stroke-color);
    --expandable-content-surface-color: var(--surface-color);
}

.ExpandableContent {
    &-Heading {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @include desktop {
            flex-basis: 100%;
        }
    }

    &-Content {
        background-color: var(--expandable-content-background-color);
        border: 1px solid var(--expandable-content-stroke-color);

        @include desktop {
            background-color: var(--expandable-content-surface-color);
            border-block-start: none;
        }
    }
}
