/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.Swatch {
    width: 100%;
    padding-block-end: 32px;
    margin-block-end: 32px;

    &:not(:last-child) {
        border-bottom: 1px dashed var(--disabled-color);

        @include mobile {
            border-bottom: none;
        }
    }

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-start: 50px;
        padding-block-end: 0;
        margin-block-end: 0;
    }

    &-Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 24px;
    }

    &-Label {
        font-family: $font-HyundaiSansHeadKR;
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
    }

    &-Value {
        font-family: $font-HyundaiSansTextKR;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        @include mobile {
            transform: translateY(6px);
        }
    }
}

