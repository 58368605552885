/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

@font-face { // Light
    font-family: 'NeoSansArabic';
    src: url(../../style/fonts/NeoSansArabicLight.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face { // Regular
    font-family: 'NeoSansArabic';
    src: url(../../style/fonts/NeoSansArabicRegular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face { // Medium
    font-family: 'NeoSansArabic';
    src: url(../../style/fonts/NeoSansArabicMedium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face { // Bold
    font-family: 'NeoSansArabic';
    src: url(../../style/fonts/NeoSansArabicBold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face { // Black
    font-family: 'NeoSansArabic';
    src: url(../../style/fonts/NeoSansArabicUltra.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
}
