/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --breadcrumbs-color: var(--hover-color);
    --breadcrumbs-hover-color: var(--secondary-blue-color);
    --breadcrumbs-disabled-color: var(--disabled-color);
    --breadcrumbs-height: 61px;
}

.Breadcrumbs {
    background-color: var(--surface-color);
    border-block-end: 1px solid var(--stroke-color);
    position: fixed;
    z-index: 10;
    width: 100%;
    inset-block-start: var(--header-height);

    @include mobile {
        display: block;
    }

    &-List {
        padding-block: 18px 0;
    }

    .ContentWrapper {
        margin-inline: 0;
    }
}
