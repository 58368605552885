/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.SmsCodePopup {
    &-ResendButton {
        padding-inline-end: 24px;
        padding-inline-start: 24px;
        height: initial;
        min-width: initial;
        font-weight: 400;
        color: var(--default-primary-black-color);
        border-color: var(--default-primary-black-color);
        line-height: 20px;
    }

    &-ValidateButton {
        line-height: 22px;

        &.Button:not([disabled]) {
            &:hover {
                line-height: 22px;
            }
        }
    }

    &-NotificationSymbol {
        background-color: var(--default-primary-blue-color);
    }

    &-Popup {
        .Popup {
            &-Content {
                border-radius: 0;
            }

            &-Header {
                background: none;
            }

            &-HeaderWrapper {
                background: none;
            }

            &-CloseBtn {
                inset-inline-end: 16px;

                path {
                    stroke: var(--default-primary-black-color);
                }
            }
        }
    }
}
