/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.PickupLocationForm {
    &-Wrapper {
        padding-block-start: 40px;

        .FieldForm {
            &-Fields {
                display: grid;
                grid-template-columns: 1fr;
                column-gap: 24px;

                @include desktop {
                    grid-template-columns: repeat(2, 1fr);
                }
            }
        }
    }

    &-RequiredLabel {
        font-size: 14px;
        line-height: 20px;
    }
}
