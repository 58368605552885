/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ChevronIcon {
    fill: none;
    cursor: pointer;

    &_direction {
        &_left {
            transform: rotate(90deg);

            [dir="rtl"] & {
                transform: rotate(-90deg);
            }
        }

        &_right {
            transform: rotate(-90deg);

            [dir="rtl"] & {
                transform: rotate(90deg);
            }
        }

        &_bottom {
            transform: none;
        }

        &_top {
            transform: rotate(180deg);
        }
    }

    path {
        stroke: #231815;
    }
}

.ChevronArrowIcon {
    &_direction {
        &_left {
            transform: rotate(180deg);

            [dir="rtl"] & {
                transform: none;
            }
        }

        &_right {
            transform: none;

            [dir="rtl"] & {
                transform: rotate(180deg);
            }
        }

        &_bottom {
            transform: rotate(90deg);
        }

        &_top {
            transform: rotate(-90deg);
        }
    }
}
