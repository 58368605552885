:root {
    --default-button-radius: 999px;
    --default-button-height: 40px;
    --button-border-width: 1px;
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, var(--hover-color));
    --button-hover-border: var(--imported_hoverButtons_border_color, var(--hover-color));
    // Secondary button (Hollow)
    --hollow-button-border: var(--imported_hollowButtons_border_color, var(--default-primary-dark-grey-color));
    --hollow-button-color: var(--imported_hollowButtons_color, var(--default-primary-dark-grey-color));
    // Secondary button (Hover)
    --hollow-button-hover-background: var(--imported_hollowHoverButtons_background_color, var(--default-primary-dark-grey-color));
    --hollow-button-hover-border: var(--imported_hollowHoverButtons_border_color, var(--default-primary-dark-grey-color));
    --hollow-button-hover-color: var(--imported_hollowHoverButtons_color,  var(--color-white));
    //Text like button
    --text-like-button-color: var(--body-content-color);
    //Text like button hover
    --text-like-button-hover: var(--secondary-blue-color);
}

.Button {
    @include Button;

    h3 {
        color: var(--default-secondary-blue-color);
    }
}
