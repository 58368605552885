@mixin button-disabled {
    opacity: 1;
}

@mixin Button {
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    font-family: var(--font-family-sans-pro);
    padding: 12px 24px;
    line-height: 20px;
    height: unset;
    border: none;

    &:not([disabled]) {
        &:hover {
            padding: 12px 24px;
            line-height: 20px;
            height: unset;
        }
    }

    .arrow-white {
        margin-inline-start: 8px;
    }

    .share-icon {
        margin-inline-end: 8px;

        path, circle {
            stroke: white;
        }
    }

    &[disabled] {
        @include button-disabled();

        background: var(--disabled-color);
        color: var(--negative-color);
        border-color: var(--disabled-color);

        .arrow-white {
            path {
                stroke: white;
            }
        }
    }

    &_isHollow {
        border-color: var(--hollow-button-border);
        border-radius: var(--hollow-button-border-radius);
        border: 1px solid;

        .arrow-white {
            pointer-events: none;

            path {
                stroke: black;
            }
        }

        &:not([disabled]) {
            &:hover {
                .arrow-white {
                    path {
                        stroke: white;
                    }
                }
            }
        }
    }

    &_likeText {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--text-like-button-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--text-like-button-hover);
        --button-height: min-content;
        --button-hover-height: min-content;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        &:disabled {
            background: transparent;
            color: var(--disabled-color);
            border-color: transparent;

            .arrow-white {
                path {
                    stroke: white;
                }
            }
        }
    }

    &_likeLink {
        --button-hover-color: var(--text-like-button-hover);

        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-base-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    line-height: 24px;
                    text-underline-position: under;
                }
            }
        }

        &:disabled {
            background: transparent;
            color: var(--disabled-color);
            border-color: transparent;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
