/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ActionsPopup {
    &-Content {
        min-height: initial;
    }

    &-Buttons {
        margin: 16px;
        column-gap: 16px;

        @include desktop {
            margin: 0;
            column-gap: 20px;
        }

        .Button {
            font-size: 16px;
            padding: 12px 24px 8px;

            &:hover {
                padding: 12px 24px 8px;
            }

            span {
                line-height: 20px;
            }
        }
    }

    .Popup {
        &-Header {
            background-color: var(--color-white);
        }

        &-HeaderWrapper {
            background-color: initial;
            padding: 0;
        }

        &-CloseBtn {
            inset-inline-end: 16px;

            @include desktop {
                inset-inline-end: -6px;
            }

            path {
                stroke: var(--default-primary-black-color);
            }
        }
    }
}
