/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.StoreItem {
    &-RadioButton {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: var(--color-white);
        border-radius: 9px;
        position: absolute;
        inset-inline-end: 20px;
        border: 1px solid var(--default-primary-grey-color);

        &_isActive {
            border: 5px solid var(--default-primary-blue-color);
        }
    }
}
