/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --monday-color: #fff;
    --tuesday-color: #fff;
    --wednesday-color: #fff;
    --thursday-color: #fff;
    --friday-color: #fff;
    --saturday-color: #fff;
    --sunday-color: #fff;
}

.ServiceBooking {
    margin-block-start: var(--header-nav-height);
    padding-block-end: 108px;
    background-color: var(--surface-color);

    &_isSecondStep {
        margin-block-start: calc(var(--header-nav-height) + var(--breadcrumbs-height));
        padding-block-end: 48px;
        margin-block-end: 0;
    }

    @include desktop {
        min-height: calc(100vh - var(--header-nav-height) - var(--footer-content-height));
    }

    &-Wrapper {
        max-width: 884px;
        margin-inline: auto;

        .Loader {
            z-index: 9;
        }

        .Form {
            &-ErrorMessages {
                display: none;
            }
        }

        .FieldForm {
            &-Notice {
                font-size: 16px;
                line-height: 24px;
                border-block-start: 1px dashed var(--separator-text-color);
                padding-block-start: 28px;
                width: 100%;

                @include desktop {
                    padding-block-start: 36px;
                }
            }

            &-QuotationSection {
                padding: 0 16px 4px 16px;
                background-color: var(--background-color);

                @include desktop {
                    padding: 0 32px 20px 32px;
                }
            }

            &-Body {
                .FieldGroup {
                    display: flex;
                    flex-flow: column nowrap;

                    @include desktop {
                        flex-flow: row wrap;
                        column-gap: 20px;
                    }

                    &-Wrapper {
                        padding: 16px;
                        background-color: var(--background-color);
                        margin-block-start: 24px;

                        @include desktop {
                            padding: 32px;
                        }

                        &_concent {
                            padding-block-end: 24px;
                        }

                        &_vehicle {
                            margin-block-start: 0;
                        }
                    }

                    &-Label {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                        margin-block: 0;
                    }

                    input:disabled {
                        color: var(--disabled-color);
                    }

                    select:disabled {
                        color: var(--disabled-color);
                    }

                    .Field-Wrapper {
                        @include desktop {
                            width: calc(50% - 10px);
                            max-width: 432px;
                        }

                        .Field {
                            margin-block-start: 24px;

                            &Date {
                                &-Calendar {
                                    .react-datepicker {
                                        &__header__dropdown {
                                            justify-content: start;
                                        }

                                        &__month {
                                            &-container {
                                                margin-block-end: 12px;
                                            }
                                        }
                                    }
                                }
                            }

                            &_type {
                                &_checkbox {
                                    .Field-CheckboxLabel {
                                        min-width: auto;

                                        span {
                                            line-height: 24px;
                                            padding-block-end: 0;
                                        }
                                    }

                                    .Field-CheckboxLink {
                                        @include linkWithArrow;
                                    }
                                }
                            }
                        }

                        &_type_textarea, &_type_checkbox {
                            min-width: 100%;
                        }

                        &_type_checkbox {
                            margin-block-start: 0;

                            &:last-child {
                                margin-block-start: 12px;
                            }

                            .Field {
                                margin-block-start: 0;
                            }
                        }

                        &_type_email, &.ContainsSubLabel, .ServicePayment-CouponInfo {
                            .Field {
                                &-SubLabelText {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: var(--secondary-blue-color);
                                    margin-inline-start: 28px;

                                    &::before {
                                        content: '';
                                        display: block;
                                        height: 20px;
                                        width: 20px;
                                        background-image: url('../../style/icons/icon-info-blue.svg');
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        position: absolute;
                                        inset-inline-start: -28px;
                                    }
                                }
                            }
                        }

                        .Field-TextArea {
                            &-CharacterCounter {
                                position: absolute;
                                inset-inline-end: 20px;
                                inset-block-end: 12px;
                                font-size: 14px;
                                line-height: 20px;
                                color: var(--disabled-color);
                            }

                            &-CharacterName {
                                padding-inline-start: 0.2em;
                            }
                        }

                        textarea {
                            width: 100%;
                            min-height: 164px;
                            font-size: 16px;

                            @include desktop {
                                min-height: 124px;
                            }
                        }

                        & .TestdriveBooking-Dealership,
                        & .TestdriveBooking-Model {
                            @include desktop {
                                margin-block-start: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &-Notification {
        font-size: 16px;
        line-height: 24px;
        margin-block-end: 32px;
        padding-inline: 16px;

        @include desktop {
            font-size: 22px;
            line-height: 28px;
            margin-block-end: 36px;
            padding-inline: 0;
        }
    }

    &-ContractNotice {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;

        @include desktop {
            font-size: 22px;
            line-height: 28px;
        }
    }

    &-Quotation {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-block-end: 12px;
    }

    &-Price {
        font-weight: 700;
    }

    &-Location {
        @include desktop {
            margin-inline-end: 10px;
        }
    }

    &-RequiredFields {
        text-align: start;
        font-size: 14px;
        line-height: 20px;
        padding: 12px 0 24px;

        @include mobile {
            padding-inline: 16px;
        }

        &-Asterisk {
            color: var(--secondary-blue-color);
            padding-inline-start: 0.2em;
        }
    }

    &-Submit {
        &Button {
            width: unset;

            @include desktop {
                width: calc(50% - 10px);
            }

            @include mobile {
                margin-inline: 16px;
            }
        }

        &Wrapper {
            background-color: var(--background-color);
            padding-block-end: 16px;

            @include desktop {
                padding: 0 32px 32px
            }

            @include mobile {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .ServiceBooking-PayLaterButton {
        background-color: var(--secondary-blue-color);
        border: none;

        @include desktop {
            width: calc(50% - 10px);
            margin-inline-start: 20px;
        }

        @include mobile {
            margin-block-start: 20px;
        }
    }

    &-NextButton {
        width: unset;

        @include desktop {
            width: 350px;
        }

        @include mobile {
            margin-inline: 16px;
        }
    }

    &-ContentWrapper {
        @include mobile {
            padding-inline: 16px;
        }

        .ServiceBooking {
            &-Heading {
                padding-block: 24px 8px;
                text-transform: none;
                font-size: 26px;
                line-height: 32px;
                font-weight: 700;

                @include desktop {
                    font-size: 40px;
                    line-height: 52px;
                    padding-block: 60px 44px;
                }
            }
        }
    }

    &-Popup {
        .Popup {
            &-Header {
                @include mobile {
                    position: static;
                }
            }

            &-ChildrenWrapper {
                display: flex;
                height: calc(100% - calc(var(--popup-padding) * 2));
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include desktop {
                    display: block;
                    text-align: center;
                    padding-block: 0 66px;
                }
            }
        }
    }

    &-ConsentPopup {
        .Popup {
            &-ChildrenWrapper {
                @include desktop {
                    padding: 60px;
                }
                
                padding: 48px 0 0;
            }

            &-Content {
                max-width: 689px;

                @include mobile {
                    max-width: 100vw;
                    position: absolute;
                    inset-block-end: 0;
                }
            }

            &-Header {
                position: absolute;
                inset-inline-end: 0;

                @include mobile {
                    padding-block-start: 16px;
                }
            }
        }

        .ServiceConsentPopup {
            @include mobile {
                padding: 0 16px 0;
            }

            &-Title {
                text-transform: none;
                margin-block-end: 20px;
                margin-block-start: 16px;
            }

            &-ListItem {
                margin-block-end: 20px;

                &::before {
                    position: relative;
                    inset-inline-start: 0;
                    padding-inline-end: 0;
                }

                &:last-child {
                    margin-block-end: 32px;
                }
            }

            &-SubmitWrapper {
                display: flex;

                @include desktop {
                    gap: 20px;
                }
            }

            &-CloseButton {
                width: 100px;
                height: 60px;
                border: 1px solid var(--stroke-color);
                background-color: transparent;
                color: var(--body-content-color);

                &:hover {
                    background-color: var(--button-background);
                    border-color: var(--button-background);
                    color: var(--button-color);
                }
            }

            &-SubmitButton {
                width: 100%;
                height: 60px;
            }
        }
    }
}
