/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable number-max-precision */
/* stylelint-disable declaration-no-important */

.CarouselScroll {
    --carousel-width: calc((var(--left-view-width) - (2 * 40px)) * var(--gallery-column-width-value)) !important;
    --carousel-scroll-gap: 4px !important;
    --animation-speed: 200ms !important; 
    
    margin-block-start: 4px;
    height: var(--additional-picture-height);
    width: 100%;

    &-ContentWrapper {
        height: 100%;
    }

    &-Content {
        height: 100%;
    }
}
