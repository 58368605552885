/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

/* stylelint-disable csstools/use-logical */

:root {
    --shadow-color: rgba(0, 0, 0, 0.1);
}

[dir="rtl"] .FieldDate-Calendar { 
    .react-datepicker {
        &__navigation {
            &-icon {
                transform: rotate(180deg);
            }

            &--previous.react-datepicker__navigation {
                left: unset;
            }

            &--next.react-datepicker__navigation {
                right: unset;
            }
        }
    }

    &_withDaysDiscounts {
        .FieldDate {
            &-DiscountTag {
                direction: ltr;
            }
        }
    }
}


.react-datepicker {
    &-wrapper {
        margin: 0;
        margin-block-start: -2px;
        width: 100%;
    }

    &__input-container {
        .CalendarIcon {
            position: absolute;
            inset-block-end: 15px;
            inset-inline-end: 20px;
        }
    }

    &__close-icon {
        @include desktop {
            inset-inline-end: 40px;
            inset-block-start: -2px;
        }
    }

    &-popper {
        z-index: 11;

        &[data-placement^=bottom] {
            padding: 2px 0 0;
        }

        &[data-placement^=top] {
            padding: 0 0 2px;
        }
    }
}

.FieldDate-Calendar {
    font-size: 16px;
    font-family: inherit;
    box-shadow: 0px 3px 6px -4px var(--shadow-color), 0px 6px 16px var(--shadow-color), 0px 9px 28px 8px var(--shadow-color);
    border-radius: 0;
    border-color: var(--stroke-color);
    padding: 12px 16px;
    width: 382px;

    @include small-mobile {
        width: 288px;
    }

    @include desktop {
        width: 432px;
    }

    .react-datepicker {
        &__month {
            margin: 0;

            &-container {
                width: 100%;
            }
        }

        &__children {
            &-container {
                pointer-events: none;
                width: 100%;
            }
        }

        &__day, &__day-name {
            padding: 0;
            margin: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
        }

        &__day {
            border-radius: 0;
            height: 32px;

            &--today {
                font-weight: inherit;
            }

            &--selected, &--keyboard-selected {
                font-weight: 700;
                background-color: var(--secondary-blue-color);
            }

            &--disabled {
                color: var(--negative-color);
            }

            &--outside-month {
                visibility: hidden;
            }
        }

        &__day-name {
            font-weight: 700;
            font-size: 14px;
            height: 18px;
            color: var(--negative-color);
        }

        &__week, &__day-names {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &__week {
            padding-block: 4px;
        }

        &__day-names {
            margin-block: 10px 4px;
        }

        &__header {
            background: none;
            border: none;
            padding: 0;

            &__dropdown {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 20px;
                padding: 8px 0;
                font-family: $font-HyundaiSansHeadKR;
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
            }
        }

        &__current-month {
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            padding-block: 8px;

            &--hasYearDropdown {
                display: none;
            }

            &--hasMonthDropdown {
                display: none;
            }
        }

        &__navigation {
            width: 28px;
            height: 28px;
            inset-block-start: 20px;
            border: 1px solid var(--stroke-color);

            &--previous {
                inset-inline-start: 16px;
            }

            &--next {
                inset-inline-end: 16px;
            }

            &-icon {
                inset-block-start: unset;
                line-height: 24px;

                &--previous {
                    inset-inline-start: -1px;
                }

                &--next {
                    inset-inline-end: -1px;
                    right: unset;
                }

                &::before {
                    border-width: 2px 2px 0 0;
                    border-color: var(--body-content-color);
                }
            }
        }

        &__month-read-view, &__year-read-view {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            &--down-arrow {
                order: 1;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                width: 10px;
                height: 16px;
                background-image: $thinChevronIcon;
                transform: rotate(-90deg) scale(0.9);
                position: static;
                border: none;
            }
        }

        &__year-dropdown {
            width: 110px;

            &--scrollable {
                @include scrollbar()
            }
        }

        &__month-dropdown {
            width: 200px;
        }

        &__year-dropdown, &__month-dropdown {
            inset-block-start: 0;
            inset-inline-start: 0;
            border-radius: 0;
            border-color: var(--stroke-color);
            background-color: $white;

            &-container {
                margin-inline: 0;
            }
        }

        &__year-option, &__month-option {
            padding: 3px 5px;
            font-family: $font-HyundaiSansTextKR;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            &--selected {
                display: none;
            }

            &:hover {
                border-radius: 0;
                background-color: var(--secondary-blue-color);
                color: $white;
            }
        }
    }

    &_withMonthYearSelector {
        .react-datepicker__navigation {
            display: none;
        }
    }

    &_withDaysDiscounts {
        padding: 24px;

        @include desktop {
            width: 400px;
        }

        .react-datepicker {
            &__dropdown {
                font-size: 16px;
                line-height: 24px;
                padding-block-start: 0;
            }

            &__header__dropdown {
                padding-block-end: 56px;
                font-size: 16px;
                line-height: 24px;
            }

            &__day {
                &--selected {
                    border: 2px solid var(--color-black);
                    color: var(--color-black);
                    font-weight: 400;
                }

                &:not(.react-datepicker__day--disabled):hover {
                    border: 1px solid var(--color-black);
                }

                &.mon:not(.react-datepicker__day--disabled) {
                    background-color: var(--monday-color);
                }

                &.tue:not(.react-datepicker__day--disabled) {
                    background-color: var(--tuesday-color);
                }

                &.wed:not(.react-datepicker__day--disabled) {
                    background-color: var(--wednesday-color);
                }

                &.thu:not(.react-datepicker__day--disabled) {
                    background-color: var(--thursday-color);
                }

                &.fri:not(.react-datepicker__day--disabled) {
                    background-color: var(--friday-color);
                }

                &.sat:not(.react-datepicker__day--disabled) {
                    background-color: var(--saturday-color);
                }

                &.sun:not(.react-datepicker__day--disabled) {
                    background-color: var(--sunday-color);
                }
            }
        }

        .FieldDate {
            &-DiscountTag {
                display: inline-block;
                font-size: 12px;
                line-height: 16px;
                padding: 4px 6px;
                min-width: 44px;
                text-align: center;

                &:not(:last-child) {
                    margin-inline-end: 5px;
                }
            }

            &-DatepickerDiscounts {
                position: absolute;
                inset-block-start: 48px;
                display: flex;
                justify-content: center;
            }

            &-DatepickerDiscountsLabel {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 16px;
                margin-inline-end: 8px;
            }

            &-DatepickerNote {
                pointer-events: none;
                font-size: 14px;
                line-height: 20px;
                margin-inline-start: 24px;
                font-weight: 700;
                color: var(--primary-error-color);

                &::before {
                    content: '';
                    display: block;
                    height: 16px;
                    width: 16px;
                    background-image: url('../../style/icons/icon-info-red.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    position: absolute;
                    inset-inline-start: -24px;
                    inset-block-end: 23px;
                }
            }
        }
    }
}

html[dir="rtl"] {
    .FieldDate {
        &-DatepickerNote {
            &::before {
                inset-block-end: 5px;
            }
        }
    }
}
