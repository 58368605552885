/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.EstimatedDelivery {
    border: none;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    gap: 6px;
    grid-gap: 6px;

    @include desktop {
        margin-block-end: 30px;
    }

    &-Image {
        width: 20px;
        height: 20px;
        background-image: $stopwatchIcon;
    }

    &-Content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        span {
            font-family: var(--font-family-sans-pro);
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);
        }
    }
}
