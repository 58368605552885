/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.FieldDate {
    &-Calendar {
        .react-datepicker {
            &__day {
                &-name {
                    color: var(--disabled-color);
                }

                &--disabled {
                    color: var(--disabled-color);

                    &.react-datepicker__day:hover {
                        background-color: transparent;
                    }
                }

                &--selected {
                    background-color: var(--primary-base-color);

                    &.react-datepicker__day:hover {
                        background-color: var(--primary-base-color);
                    }
                }

                &:hover {
                    background-color: var(--disabled-color);
                }
            }

            &__month,
            &__year {
                &-option {
                    &:hover {
                        background-color: var(--color-dark-gray);
                        color: var(--primary-base-color);
                    }
                }
            }
        }

        &_withDaysDiscounts {
            border-radius: var(--input-border-radius);
            width: 288px;

            @include desktop {
                width: 390px;
            }

            .react-datepicker {
                &__day {
                    border-radius: var(--input-border-radius);
                    height: 30px;

                    @include desktop {
                        height: 40px;
                    }

                    &--selected {
                        &.react-datepicker__day:hover {
                            background-color: unset;
                        }

                        &.mon:hover {
                            background-color: var(--monday-color);
                        }
            
                        &.tue:hover {
                            background-color: var(--tuesday-color);
                        }
            
                        &.wed:hover {
                            background-color: var(--wednesday-color);
                        }
            
                        &.thu:hover {
                            background-color: var(--thursday-color);
                        }
            
                        &.fri:hover {
                            background-color: var(--friday-color);
                        }
            
                        &.sat:hover {
                            background-color: var(--saturday-color);
                        }
                
                        &.sun:hover {
                            background-color: var(--sunday-color);
                        }
                    }
                }

                &__header { 
                    &__dropdown {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &-DiscountTag {
        border-radius: var(--input-border-radius);
    }

    &-DatepickerNote {
        color: var(--primary-error-color);
    }

    &-DatepickerDiscountsLabel {
        color: var(--default-primary-black-color);
    }
}

.react-datepicker {
    &-popper {
        z-index: 97;
    }
}

html[dir="rtl"] {
    .FieldDate {
        &-DatepickerNote {
            &::before {
                @include mobile {
                    inset-block-end: 23px;
                }
            }
        }
    }
}
