/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ServicePayment {
    --icon-margin: 24px;

    &-RadioWrapper {
        background-color: var(--color-white);
        border-radius: var(--input-border-radius);
        border-color: var(--default-primary-grey-color);

        &_checked {
            border: 1px solid var(--primary-base-color);
        }
    }

    &-RadioSubLabel {
        color: var(--secondary-dark-color);
        font-size: 12px;
        line-height: 18px;
    }

    &-DiscountLabelWrapper {
        background-image: none;
        width: unset;
    }

    &-DiscountLabelText {
        color: var(--color-white);
        padding: 6px 12px 4px;
        background-color: var(--primary-base-color);
        border-radius: var(--input-border-radius);
        text-transform: uppercase;
        font-weight: 500;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Label {
        margin-block-end: 36px;
        color: var(--default-primary-black-color);
    }

    &-Coupon {
        &Info {
            color: var(--default-primary-silver-color);

            &::before {
                height: 16px;
                width: 16px;
                background-image: url('../../style/icons/icon-info-gray.svg');
            }
        }
    }

    &-CouponClear {
        inset-inline-start: unset;
        inset-inline-end: 103px;
        inset-block-end: 16px;
    }

    &-DiscountReducedPrice,
    &-DiscountFullPrice {
        font-weight: 500;
    }

    &-DiscountWrapper {
        &_hasDiscount {
            .ServicePayment {
                &-DiscountFullPrice {
                    font-weight: 500;
                }
            }
        }
    }

    &-DiscountFullPrice {
        color: var(--primary-base-color);
    }
}

.FieldGroup {
    .ServicePayment-CouponWrapper {
        .ServicePayment {
            &-CouponInputRow {
                display: block;
                
                @include desktop {
                    width: fit-content;
                }
        
                .Field-Wrapper { 
                    &.Field {
                        &-Wrapper {
                            &_type {
                                &_button {
                                    width: fit-content;
                                    position: absolute;
                                    inset-inline-end: 4px;
                                    inset-block-end: 4px;
            
                                    .Field {
                                        margin: 0;

                                        button {
                                            background-color: var(--primary-base-color);
                                            color: var(--color-white);
                                            border-radius: var(--input-border-radius);
                                            padding: 12px 20px 8px;
                                            min-width: 80px;
                                            width: unset;
                                            line-height: 20px;
                                            max-height: 40px;
                                            font-weight: 400;
                                        }
                                    }
                                }

                                &_text {
                                    @include desktop {
                                        width: 390px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
