// Default colors
$default-primary-blue-color: #000000;
$default-secondary-blue-color: #FFFFFF;
$default-primary-black-color: #231815;
$default-primary-dark-grey-color: #2E2E2E;
$default-primary-silver-color: #595757;
$default-primary-grey-color: #CBD5DC;
$default-primary-light-grey-color: #F4F5F7;
$default-secondary-gold-color: #BE9E7F;
$default-secondary-white-color: #FFFFFF;
$default-secondary-grey-color: #949494;
// Other colors
$primary-text-color: #262626;
$primary-disabled-color: #CCCCCC;
$error-color: #E02B27;
$negative-color: #FFFFFF;
$stroke-color: #CBD5DC;
$separator-text-color: #D9D9D9;
$negativeColorHover: #CBD5DC;
$scrollThumbColor: #949494;
$textPlaceholderColor: #555858;

$surface-color: #F4F5F7;

$productPageMainHeight: calc(100vh - var(--header-nav-height) - var(--breadcrumbs-height) - var(--add-to-card-height) - var(--product-title-height) - 8px);

:root {
    --default-primary-blue-color: #{$default-primary-blue-color};
    --default-secondary-blue-color: #{$default-secondary-blue-color};
    --default-primary-black-color: #{$default-primary-black-color};
    --default-primary-dark-grey-color: #{$default-primary-dark-grey-color};
    --default-primary-silver-color: #{$default-primary-silver-color};
    --default-primary-grey-color: #{$default-primary-grey-color};
    --default-primary-light-grey-color: #{$default-primary-light-grey-color};
    --default-secondary-gold-color: #{$default-secondary-gold-color};
    --default-secondary-white-color: #{$default-secondary-white-color};
    --hover-color: #{$default-primary-dark-grey-color};
    --secondary-hover-color: #{$default-primary-blue-color};
    --body-content-color: #{$primary-text-color};
    --disabled-color: #{$primary-disabled-color};
    --primary-error-color: #{$error-color};
    --background-color: #{$default-primary-light-grey-color};
    --surface-color: #{$default-secondary-white-color};
    --stroke-color: #{$stroke-color};
    --negative-color: #{$negative-color};
    --separator-text-color: #{$separator-text-color};
    --negative-color-hover: #{$negativeColorHover};
    --add-to-card-height: #{$addToCartHeight};
    --product-title-height: #{$productTitleHeight};
    --product-page-main-height: #{$productPageMainHeight};
    --scroll-thumb-color: #{$scrollThumbColor};
    --default-secondary-grey-color: #{$default-secondary-grey-color};
    --text-placeholder-color: #{$textPlaceholderColor};
}
