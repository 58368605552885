/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CompareTrims {
    th {
        font-family: var(--font-family-sans-pro);
    }

    td, th {
        html[dir="rtl"] & {
            border-left: 1px solid var(--stroke-color);
            border-right: 0;
        }
    }

    &-Breadcrumbs.Breadcrumbs {
        height: var(--breadcrumbs-height);
        padding: 16px 40px;
    }

    &-ScrollButton {
        &_isDisabled {
            svg {
                fill: none;

                path {
                    stroke: var(--disabled-color);
                }
            }
        }
    }
    
    &-Wrapper {
        &_isScrolled::after {
            html[dir="rtl"] & {
                box-shadow: -23px 0px 25px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }

    &-Heading {
        h3 {
            margin-block: 0;
            margin-inline: 0 auto;
        }
    }
}
