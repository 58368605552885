.CustomerCare {
    padding: 72px 16px;

    @include desktop {
        padding: 80px 0 72px;
    }

    &-Heading {
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 12px;

        @include desktop {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &-Content {
        flex-grow: 1;
    }

    &-Heading,
    &-Text {
        text-align: center;
    }

    &-Text {
        font-size: 16px;
        line-height: 24px;
        color: var(--default-primary-silver-color);
        margin: 0 0 24px;

        @include desktop {
            margin: 0 0 48px;
        }
    }

    &-ImageWrapper {
        border-radius: 2px;
        margin-block: 4px 0;
        margin-inline: 4px;
        overflow: hidden;

        @include desktop {
            margin-block: 4px;
            margin-inline: 4px 0;
        }
    }

    &-Image {
        display: none;

        @include desktop {
            display: block;
            width: 120px;
            height: 120px;
        }

        &_isMobile {
            display: block;

            @include desktop {
                display: none;
            }
        }
    }

    &-BookingsBlock {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        @include desktop {
            flex-direction: row;
            column-gap: 32px;
            justify-content: center;
        }
    }

    &-Booking {
        background-color: var(--default-primary-light-grey-color);
        border-radius: 6px;

        @include desktop {
            width: 392px;
            display: flex;
            align-items: center;
        }
    }

    h3 {
        &.CustomerCare-SubHeading {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &-SubHeading {
        margin: 12px 12px 8px;
        font-weight: 500;
        text-transform: uppercase;

        @include desktop {
            margin: 0 24px 12px;
        }
    }

    &-Link {
        margin: 0 12px 16px;
        display: inline-flex;
        align-items: baseline;

        @include desktop {
            margin: 0 24px 0;
        }

        &:hover {
            .CustomerCare-Arrow {
                path {
                    stroke: var(--default-primary-black-color);
                }
            }
        }
    }

    &-Arrow {
        margin-inline-start: 8px;

        &:dir(rtl) {
            transform: rotate(180deg);
        }

        path {
            stroke: var(--default-primary-blue-color);
            will-change: stroke;
        }
    }
}
