/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.StickyImage {
    &-Normal {
        position: fixed;
        inset-block-start: calc(var(--header-height) + var(--breadcrumbs-height));
    }

    &-Top {
        position: absolute;
        inset-block-start: 0;
        inset-block-end: unset;
    }

    &-Bottom {
        position: absolute;
        inset-block-start: unset;
        inset-block-end: 0;
    }
}
