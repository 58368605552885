/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

:root {
    --footer-background-color: var(--default-primary-light-grey-color);
    --footer-primary-color: var(--primary-base-color);
    --footer-stroke-color: var(--stroke-color);
    --footer-content-height: #{$footerContentHeightDesktop};
    --footer-copyright-height: #{$footerCopyrightDesktop};
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height))
}

.Footer {
    z-index: 95;
    padding: 0;
    background-color: var(--footer-background-color);
    border-block-start: none;
    margin: 0;

    &-Columns {
        @include desktop {
            height: var(--footer-content-height);
            padding: 0 !important;
        }

        margin: 0;
        width: 100%;
        max-width: 100%;
    }

    &-Column {
        height: 100%;
        border-block-start: none;

        &:first-child {
            @include mobile {
                display: flex;
                flex-direction: column;
                padding: 48px 60px 32px 60px;
                justify-content: center;
                align-items: center;
                order: 0;
            }

            @include tablet {
                padding: 48px 30vw 32px 30vw;
            }

            @include iPadMini {
                padding: 48px 30vw 32px 30vw;
            }

            @include desktop {
                border-inline-start: none;
                width: unset;
                padding: 60px 70px 112px 40px;
                border-block: none;
            }
        }

        &:nth-child(2) {
            .Footer-ColumnContent {
                flex-direction: unset;
            }

            @include desktop {
                width: unset;
                border-block-start: none;
            }

            @include mobile {
                border-block-start: 1px solid var(--default-secondary-white-color);
                order: 2;
            }
        }

        &:nth-child(3) {
            @include desktop {
                flex: 2;
                width: unset;
            }

            @include mobile {
                order: 1;
            }
        }

        &:last-child {
            @include desktop {
                width: unset;
                margin: 0;
                padding: 60px 40px 60px 70px;
            }

            @include mobile {
                order: 3;
            }
        }

        &:not(:last-of-type) {
            margin: 0;

            @include desktop {
                padding-inline-end: 70px;
            }
        }

        @include desktop {
            width: auto;
            border-block: none;
            flex-direction: row;
            padding: 60px 70px 112px 70px;
            border-inline-start: 1px solid var(--default-secondary-white-color);
        }

        @include mobile {
            border-block-start: 1px solid var(--default-secondary-white-color);
            padding: 32px 16px;
        }
    }

    &-Logo {
        @include mobile {
            width: 100%;
            margin-block-end: 24px;
        }
    }

    &-SecondaryLogo {
        mix-blend-mode: multiply;

        @include mobile {
            width: 100%;
        }
    }

    &-ColumnContent {
        @include desktop {
            justify-content: space-between;
            height: 100%;
        }

        a {
            line-height: 24px;
            font-size: 16px;
            font-weight: 700;
            font-family: var(--font-family-sans-pro);
            color: var(--default-primary-silver-color);

            &:hover {
                text-decoration: none;
                border: none;
            }

            @include mobile {
                &:not(:last-child) {
                    margin-block-end: 20px;
                }
            }
        }
    }

    &-PrivacyPolicy {
        justify-content: flex-start;

        @include desktop {
            align-items: start;
            gap: 16px;
        }
    }

    &-MapIcon,
    &-PhoneIcon,
    &-EmailIcon {
        padding-inline-start: 22px;
        line-height: 24px;

        @include mobile {
            max-width: 90%;
            margin-block-end: 0;
            margin-inline: 0 !important;
        }

        &::before {
            height: 16px;
            width: 16px;
            display: inline-block;
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            inset-block-start: 0;
            inset-inline-start: 0;
            position: absolute;
        }
    }

    &-MapIcon {
        @media (max-width: 531px) {
            &::before {
                inset-block-start: 10px;
            }
        }

        + a {
            font-size: 16px;
            font-weight: 400;
            color: var(--default-primary-silver-color);
            line-height: 24px;
            cursor: pointer;
            margin: 0;
        }

        &::before {
            background-image: $map-icon;
            
            @include desktop {
                inset-block-start: 5px;
            }

            @include mobile {
                inset-block-start: 3px;
            }
        }
    }

    &-PhoneIcon {
        &::before {
            inset-block-start: 5px;
            background-image: $phone-icon;
        }

        @include mobile {
            margin: 20px 0 20px 0 !important;
        }
    }

    .NewsletterSubscription {
        @include desktop {
            min-width: unset;
        }

        .Field_type_checkbox {
            margin-block-start: 15px;
    
            span {
                color: var(--default-primary-silver-color);
                font-family: var(--font-family-sans-pro);
                font-size: 14px;
                font-weight: 400;

                @include mobile {
                    &::after {
                        display: block;
                        content: '*';
                        color: var(--primary-error-color);
                    }
                }
            }
    
            .input-control {
                transform: scale(0.8);
            }
    
            .Field-Label_isRequired {
                padding: 0;
                color: var(--primary-error-color);
            }
        }

        .Field_type_email {
            input {
                height: 48px;
            }
        }

        .Button {
            position: absolute;
            height: 40px;
            width: 40px;
            background-image: $mail-icon;
            background-repeat: no-repeat;
            border-radius: 2px;
            inset-block-start: 4px;
            inset-inline-end: 4px;
            padding: 0;
            margin-block-start: 0;
            margin-inline-start: 0;

            @include desktop {
                height: 40px;
                width: 40px;
                padding: 0;
                margin-block-start: 0;
                margin-inline-start: 0;
            }

            @include tablet {
                margin: 0;
                width: 40px;
                margin-inline-start: 0;
            }
        }

        &-TermsAndConditions-CheckboxFormMobile {
            margin-block-start: 15px !important;
        }
    }

    &-Copyright {
        padding: 0 40px;
        display: flex;
        background-color: var(--default-secondary-white-color);
        font-family: var(--font-family-sans-pro);

        span {
            color: var(--default-primary-silver-color);
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;

            a {
                color: var(--default-primary-silver-color);
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                text-decoration: none;
                cursor: pointer;
            }
        }

        @include desktop {
            height: var(--footer-copyright-height);
            justify-content: space-between;
            align-items: center;
        }

        @include mobile {
            padding: 16px 0;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            span {
                &:nth-child(2) {
                    margin-block-end: 8px;
                }
            }
        }
    }

    @include desktop {
        padding: 0;
        height: $footerHeightDesktop;
        overflow: hidden;
    }

    &-ColumnItem {
        font-size: 16px;
        font-weight: 400;
        color: var(--default-primary-silver-color);
        line-height: 24px;
    }

    &-SocialIcons {
        margin-block-end: 0;
        margin-block-start: 10px;
        height: unset;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 246px;

        @include desktop {
            justify-content: flex-start;
            width: 200px;
        }

        span {
            transform: scale(1.7);

            img {
                cursor: default !important;
            }
        }

        br {
            display: none;
        }

        a {
            margin-block-end: 0;
        }

        @include mobile {
            a {
                margin-block-end: 0 !important;
                margin-inline-start: 7px;
                flex: unset;
            }

            span {
                margin-inline-start: 7px;
                transform: scale(1.9);
            }
        }
    }
}
