/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --notification-success-background: var(--imported_notificationSuccess_backgroud_color, var(--default-primary-blue-color));
    --notification-error-backgroud: var(--imported_notificationError_backgroud_color, var(--primary-error-color));
    --notification-info-background: var(--imported_notificationInfo_backgroud_color, var(--default-secondary-gold-color));
    --default-notification-height: 38px;
    --notification-info-color: var(--imported_notificationInfo_color, var(--default-secondary-white-color));
    --notification-error-color: var(--imported_notificationError_color, var(--default-secondary-white-color));
    --notification-success-color: var(--imported_notificationSuccess_color, var(--default-secondary-white-color));
}

.Notification {
    border-radius: 6px;
    padding: 8px 12px 8px 20px;

    &-Text {
        margin-block-start: 0;
        font-weight: 400;
        line-height: 20px;
        font-family: var(--font-family-sans-pro);
        font-size: 14px;
        margin-inline-end: 40px;
        margin-block-end: 0;
    }

    &-Button {
        cursor: pointer;
        font-size: 0;
        appearance: none;
        background: none;
        position: absolute;
        inset-inline-end: 12px;
        inset-block-start: 12px;
        padding: 0;
        width: 12px;
        height: 12px;
        opacity: 1;
    
        @include mobile {
            margin: auto;
            inset-block-end: 0;
            inset-inline-end: 14px;
        }
    
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 10.5L10.5 1.5M1.5 1.5L10.5 10.5' stroke='white' stroke-width='1.2'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;

        &:focus {
            outline: none;
        }
    
        &::before,
        &::after {
            display: none;
            position: absolute;
            inset-block-start: 0;
            inset-block-end: 0;
            inset-inline-end: 0;
            inset-inline-start: 0;
            margin: auto;
            content: none;
            height: 0;
            width: 0;
            background-color: var(--notification-color);
        }
    }
}
