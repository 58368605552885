:root {
    // h1 desktop
    --h1-font-size: var(--imported_h1_font_size, 32px);
    --h1-color: var(--imported_h1_color, var(--default-primary-black-color));
    --h1-line-height: var(--imported_h1_line_height, 36px);

    // h1 mobile
    --h1-font-size-mobile: var(--imported_h1Mobile_font_size, 24px);
    --h1-color-mobile: var(--imported_h1Mobile_color, var(--body-content-color));
    --h1-line-height-mobile: var(--imported_h1Mobile_line_height, 32px);

    // h2 desktop
    --h2-font-size: var(--imported_h2_font_size, 24px);
    --h2-color: var(--imported_h2_color, var(--body-content-color));
    --h2-line-height: var(--imported_h2_line_height, 32px);

    // h2 mobile
    --h2-font-size-mobile: var(--imported_h2Mobile_font_size, 20px);
    --h2-color-mobile: var(--imported_h2Mobile_color, var(--body-content-color));
    --h2-line-height-mobile: var(--imported_h2Mobile_line_height, 28px);

    // h3 desktop
    --h3-font-size: var(--imported_h3_font_size, 20px);
    --h3-color: var(--imported_h3_color, var(--body-content-color));
    --h3-line-height: var(--imported_h3_line_height, 24px);

    // h3 mobile
    --h3-font-size-mobile: var(--imported_h3Mobile_font_size, 16px);
    --h3-color-mobile: var(--imported_h3Mobile_color, var(--body-content-color));
    --h3-line-height-mobile: var(--imported_h3Mobile_line_height, 24px);

    // paragraph
    --paragraph-font-size: var(--imported_paragraph_font_size, 16px);
    --paragraph-color: var(--imported_paragraph_color, var(--body-content-color));
    --paragraph-line-height: var(--imported_paragraph_line_height, 24px);

    // paragraph mobile
    --paragraph-font-size-mobile: var(--imported_paragraphMobile_font_size, 16px);
    --paragraph-color-mobile: var(--imported_paragraphMobile_color, var(--body-content-color));
    --paragraph-line-height-mobile: var(--imported_paragraphMobile_line_height, 24px);

    // caption
    --caption-font-size: var(--imported_caption_font_size, 14px);
    --caption-color: var(--imported_caption_color, var(--body-content-color));
    --caption-line-height: var(--imported_caption_line_height, 20px);

    // caption mobile
    --caption-font-size-mobile: var(--imported_captionMobile_font_size, 14px);
    --caption-color-mobile: var(--imported_captionMobile_color, var(--body-content-color));
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 20px);

    --font-family-sans-pro: $font-Arial;
}

html {
    &[dir=rtl] {
        --font-family-sans-pro: 'NeoSansArabic', 'open-sans';
    }

    &[dir=ltr] {
        --font-family-sans-pro: 'OpenSans', 'open-sans';
    }
}

body {
    font-family: var(--font-family-sans-pro);
    color: var(--body-content-color);
    overflow-x: hidden;
    overflow-y: auto;

    @include scrollbar();
}

.scrollDisabled {
    /*
    *  Fix bug on iOS devices when document's scroll appears when it shouldn't be while popup(overlay) is opened
    */
    overflow: hidden;
    position: fixed;
    height: 100%;
}

a {
    color: var(--primary-base-color);
}

h1, h2, h3, h4 {
    font-family: var(--font-family-sans-pro);
}

p {
    &.caption {
        a {
            font-size: var(--caption-font-size);
            line-height: var(--caption-line-height);
        }

        &#captionStrikeThrough {
            text-decoration: line-through;
        }
    }
}
