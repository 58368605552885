/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --loader-scale: 0.4;
}

.Loader {
    .SkylineLoader {
        width: initial;
        height: initial;
        background-image: none;
        transform: none;
    }

    &-PaymentMain {
        & path {
            &:nth-child(1) {
                fill: var(--stroke-color);
            }

            &:nth-child(2) {
                stroke: var(--default-secondary-blue-color);
                animation: loading-spinner 1500ms ease-in-out infinite;
                will-change: transform;
                transform-origin: 50% 50%;
            }
        }
    }
}
