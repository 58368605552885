/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --breadcrumbs-color: var(--default-primary-silver-color);
    --breadcrumbs-hover-color: var(--primary-silver-color);
    --breadcrumbs-disabled-color: var(--disabled-color);
    --breadcrumbs-height: 52px;
}

.Breadcrumbs {
    border-block-end: none;
    position: relative;
    z-index: initial;
    inset-block-start: 0;

    @include desktop {
        position: fixed;
        z-index: 50;
        width: 100%;
        inset-block-start: var(--header-height);
        background-color: var(--default-primary-light-grey-color);
    }

    &-List {
        padding: 24px 0 0;

        @include desktop {
            padding: 16px 0;
        }
    }

    .ContentWrapper {
        margin-inline: 0;

        @include desktop {
            padding: 0 40px;
        }
    }
}
