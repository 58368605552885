/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.MyVehicles-Pod {
    &-Wrapper {
        margin: 0 16px 36px;

        @include desktop {
            margin: 0 52px 40px;
        }
    }

    &-Product,
    &-ActionsWrapper {
        background-color: var(--background-color);
    }

    &-Product {
        padding: 36px 16px 20px 16px;

        @include desktop {
            display: flex;
            padding: 52px 40px 20px 40px;
        }

        &_finished {
            background-color: var(--shadow-color);
        }
    }

    &-CancelButton {
        position: absolute;
        inset-inline-end: 18px;
        inset-block-start: 18px;
        z-index: 1;

        @include desktop {
            inset-inline-end: 20px;
            inset-block-start: 20px;
        }
    }

    &-ImageWrapper {
        width: 100%;
        margin-block-end: 28px;
        display: flex;
        align-items: center;

        @include desktop {
            width: 498px;
            margin-inline-end: 72px;
        }

        img {
            height: unset;
        }
    }

    &-Image {
        height: unset;
    }

    &-RightSide {
        flex-grow: 1;
    }

    &-NameWrapper,
    &-InfoWrapper {
        border-bottom: 3px solid var(--body-content-color);
    }

    &-NameWrapper {
        display: flex;
        align-items: baseline;
    }

    &-Model {
        margin-block: 0 16px;
        font-size: 22px;
    }

    &-Trim {
        font-size: 16px;
        margin-inline-start: 12px;
    }

    &-OrderInfo {
        display: flex;
        flex-wrap: wrap;
        gap: 8px 0;
    }

    &-OrderInfo,
    &-ProductInfo {
        padding: 20px 0;
    }

    &-ProductInfo {
        .MyVehicles-Pod-InfoValue {
            padding-inline-start: 14px;

            @include desktop {
                padding: 0;
            }
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
            gap: 8px 0;
        }

        li {
            margin: 0;
            padding-inline-start: 14px;
        }

        border-block-start: 1px dashed var(--disabled-color);
    }

    &-InfoName,
    &-InfoValue,
    &-TotalPM {
        font-size: 14px;
        line-height: 20px;
    }

    &-InfoName {
        width: 50%;
        flex-grow: 1;

        &_isBold {
            font-weight: 700;
        }

        &_isReducedWidth {
            width: 10%;
        }
    }

    &-InfoValue {
        font-weight: 700;
        min-width: 5%;

        @include desktop {
            padding: 0;
            text-align: end;
        }

        &.CreatedDate {
            font-weight: 400;
        }

        &.Options {
            max-width: 145px;
        }

        &.Colors {
            text-transform: capitalize;
        }

        &_isDefault {
            font-weight: 400;
        }
    }

    &-Totals {
        text-align: end;
        margin-block-end: 32px;
    }

    &-Total {
        margin-block: 0 4px;
    }

    &-ActionsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-block-start: 1px solid var(--stroke-color);

        @include desktop {
            flex-direction: row;
            align-items: stretch;
            border: none;
            margin: 1px 0 0;
        }
    }

    &-DealerMessage {
        flex-grow: 1;
        font-size: 16px;
        line-height: 24px;
        padding: 20px 16px 24px 16px;
        font-weight: 700;
        color: var(--secondary-blue-color);

        @include desktop {
            padding: 18px 32px 18px 40px;
        }
    }

    &-ButtonSecondary {
        @include mobile {
            margin-block-end: 20px;
        }

        &.Button {
            background-color: var(--secondary-blue-color);
            border-color: var(--secondary-blue-color);
        }

        &:hover {
            &.Button:hover {
                background-color: var(--secondary-hover-color);
                border-color: var(--secondary-hover-color);
            }
        }
    }

    &-Actions {
        padding: 0 16px 36px 16px;

        @include desktop {
            display: flex;
            align-items: stretch;
            padding: 0;
        }

        @include mobile {
            width: 100%;
        }

        .Button {
            min-width: 100%;

            @include desktop {
                min-width: 220px;
            }
        }

        .MyVehicles-Pod-ButtonSecondary, 
        .MyVehicles-Pod-Button {
            @include desktop {
                height: 100%;
            }

            &:not([disabled]) {
                &:hover {
                    @include desktop {
                        height: 100%
                    }
                }
            }
        }
    }

    &-SubTotalWrapper {
        border-bottom: 1px dashed var(--disabled-color);
        padding: 20px 0;
    }

    &-TotalElement {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-inline-start: 0;
        margin-block-end: 8px;
        
        &::before {
            display: none;
        }

        &:last-child {
            margin-block-end: 0;
        }
    }

    &-TotalWrapper {
        margin: 20px 0 0;
        
        .MyVehicles-Pod-TotalElement {
            margin-block-end: 4px;

            &_isNormalMargin {
                margin-block-end: 8px;
            }
        }
    }

    &-GrandTotalValue {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
        margin: 0;

        @include desktop {
            font-size: 24px;
        }
    }
}
