/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.MyVehicles {
    &-NoOrdersButton {
        @include desktop {
            width: 196px;
            max-width: unset;
        }
    }

    &-NoOrdersContent {
        @include mobile {
            padding: 0 16px;
        }
    }

    &-Pod {
        &-Product {
            gap: 72px;
        }

        &-ImageWrapper {
            /* stylelint-disable-next-line declaration-no-important */
            margin: 0 !important;
        }
    }

    &-Label {
        inset-inline-start: 32px;
        inset-block-start: 14px;
        padding: 6px 12px 2px;
        padding-inline-start: 12px;
        padding-inline-end: 12px;
        font-family: var(--font-family-sans-pro);
        color: var(--default-primary-black-color);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background-color: var(--default-primary-grey-color);
        clip-path: none;
        border-radius: 6px;

        @include desktop {
            inset-inline-start: 56px;
            inset-block-start: 13px;
        }

        &:dir(rtl) {
            clip-path: none;
        }
    }
}

.ReservationPopup {
    &-Loader {
        .Loader {
            &-Scale {
                --loader-scale: 1;

                inset-block-start: calc(50% - (-40px * var(--loader-scale)));
            }
        }
    }
}
