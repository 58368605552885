/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

/* stylelint-disable csstools/use-logical */

:root {
    @include desktop {
        --header-top-menu-height: 0px;
    }
}

.Header {
    border-block-end: 1px solid var(--stroke-color);

    &_name_popup {
        border-block-end: none;


        .Header {
            &-Button {
                &_type_close {
                    order: 1;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;

                    @include mobile {
                        width: 16px;
                        height: 16px;

                        .CloseIcon {
                            transform: scale(1.2);
                        }
                    }
                }
            }

            &-MenuOverlayButtonWrapper {
                display: none;
            }

            &-Title {
                @include mobile {
                    text-align: start;
                    font-size: 22px;
                }

                &_isVisible {
                    @include mobile {
                        max-width: calc(100% - 30px);
                    }
                }
            }

            &-Nav {
                border-block-end: 1px solid var(--stroke-color);
            }
        }
    }

    &-MenuButton {
        @include desktop {
            margin-inline-start: 20px;
        }

        @include mobile {
            margin-inline-start: 10px;
        }

        display: flex;
    }

    &-MenuLoaderWrapper {
        position: fixed;
        inset-block-start: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
    }

    &-MyAccountContainer {
        @include desktop {
            margin-inline-start: 20px;
        }

        @include mobile {
            margin-block-start: 6px;
            margin-inline: 0 10px;
        }
    }

    &-Wrapper {
        margin-block-end: var(--header-nav-height);

        @include mobile {
            z-index: auto;

            &_isHidden {
                display: none;
            }
        }
    }

    &-Nav {
        max-width: unset;
        display: flex;

        @include ultra-wide-desktop {
            padding: 0 52px;
        }
    }

    &-LogoWrapper {
        position: relative;

        @include mobile {
            margin: unset;
        }
    }

    &-MenuSpace {
        width: 0;
        margin-inline-start: auto;
    }

    &-MenuOverlayButtonWrapper {
        display: none;

        &_isVisible {
            display: block;

            [dir="rtl"] & {
                margin-right: 10px;
            }
        }
    }
}
