.GeelyFinancing {
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;

    @include desktop {
        flex-direction: row;
    }

    &-ImageBlock,
    &-TextBlock {
        @include desktop {
            width: 50%;
        }

        @include super-wide-desktop {
            width: initial;
        }
    }

    &-TextBlock {
        margin: 24px 16px 0;

        @include desktop {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding-inline-end: 100px;
            padding-inline-start: 36px;
            justify-content: center;
        }

        @include super-wide-desktop {
            padding-inline-end: 100px;
            padding-inline-start: 80px;
        }
    }

    &-Heading {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 28px;
        line-height: 34px;
        margin: 0 0 12px;

        @include desktop {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &-Paragraph {
        color: var(--default-primary-silver-color);
        font-size: 16px;
        line-height: 24px;
        max-width: 780px;

        &:last-of-type {
            margin: 0;
        }
    }

    &-Button {
        @include desktop {
            max-width: fit-content;
        }

        h2 {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            padding: 0;
            margin: 0;
            line-height: 20px;
        }
    }

    &-Image {
        display: none;

        @include desktop {
            display: block;
            height: 432px;
            width: 100%;

            &:dir(rtl) {
                border-radius: 12px 0 0 12px;
                transform: translateX(12px);
            }
        }

        @media (min-width: 1355px) {
            width: initial;
        }

        @include super-wide-desktop {
            border-radius: 12px 0 0 12px;

            &:dir(rtl) {
                transform: none
            }
        }

        &_isMobile {
            display: block;

            @include desktop {
                display: none;
            }
        }
    }
}
