/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.FieldSelect {
    .ChevronIcon {
        fill: none;
        inset-inline-end: 20px;
    }

    &-Select {
        color: var(--text-placeholder-color);
        opacity: 1;

        &_isSelected {
            color: var(--body-content-color);
        }

        &:disabled + svg {
            fill: none;

            path {
                stroke: var(--disabled-color);
            }
        }
    }

    &-Options {
        &_isExpanded {
            max-height: 200px;
        }
    }

    &-Option {
        padding-inline-start: 20px;
        padding-block: 8px;
        line-height: 24px;
        max-height: 40px;

        &:hover, &:focus {
            @include desktop {
                background-color: var(--disabled-color);
                color: var(--default-primary-dark-grey-color);
            }
        }

        &_isHovered {
            @include desktop {
                background-color: var(--disabled-color);
                color: var(--default-primary-dark-grey-color);
            }
        }

        &_isPlaceholder,
        &_isDisabled {
            opacity: 1;
            color: var(--text-placeholder-color);
        }
    }
}
