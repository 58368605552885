/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ShowroomBooking {
    &-Wrapper {
        max-width: 884px;
        margin-inline: auto;
        padding-inline: 16px;

        @include desktop {
            padding-inline: 0;
        }

        .react-datepicker-popper {
            &[data-placement^=bottom] {
                padding-block-start: 2px;
            }

            &[data-placement^=top] {
                padding-block-end: 2px;
            }
        }

        .FieldForm {
            &-Notice {
                font-size: 16px;
                line-height: 24px;
                border-block-start: 1px dashed var(--separator-text-color);
                padding-block-start: 28px;

                @include desktop {
                    padding-block-start: 36px;
                }
            }

            &-Fields {
                display: flex;
                flex-flow: column nowrap;
                margin-block-end: 40px;

                input:disabled {
                    color: var(--disabled-color);
                }

                select:disabled {
                    color: var(--disabled-color);
                }

                @include desktop {
                    flex-flow: row wrap;
                    column-gap: 20px;
                }

                .Field-Wrapper {
                    @include desktop {
                        width: calc(50% - 10px);
                        max-width: 432px;
                    }

                    &:first-child {
                        .ShowroomBooking-Dealership {
                            margin-block-start: 0;
                        }
                    }

                    &_type_textarea, &_type_checkbox {
                        min-width: 100%;
                    }

                    &_type_checkbox {
                        margin-block-start: 28px;

                        & + .Field-Wrapper_type_checkbox {
                            margin: 0;
                        }

                        .Field {
                            margin-block-start: 12px;
                        }
                    }

                    .Field_type_checkbox {
                        .Field-CheckboxLabel {
                            min-width: auto;

                            span {
                                line-height: 24px;
                                padding-block-end: 0;
                            }
                        }

                        .Field-CheckboxLink {
                            @include linkWithArrow;
                        }
                    }

                    .Field-TextArea {
                        &-CharacterCounter {
                            position: absolute;
                            inset-inline-end: 20px;
                            inset-block-end: 12px;
                            font-size: 14px;
                            line-height: 20px;
                            color: var(--disabled-color);
                        }

                        &-CharacterName {
                            padding-inline-start: 0.2em;
                        }
                    }

                    textarea {
                        width: 100%;
                        min-height: 164px;
                        font-size: 16px;

                        @include desktop {
                            min-height: 124px;
                        }
                    }
                }
            }
        }
    }

    &-RequiredFields {
        text-align: start;
        font-size: 14px;
        line-height: 20px;
        padding-block: 32px;

        @include desktop {
            text-align: end;
            padding-block: 36px 0;
        }

        &-Asterisk {
            color: var(--secondary-blue-color);
            padding-inline-start: 0.2em;
        }
    }

    &-Submit {
        width: 100%;

        &-Wrapper {
            margin-block-end: 109px;
        }
    }

    .TestdriveBooking-Model {
        @include desktop {
            margin-block-start: 0;
        }
    }

    &-Dealership + .TestdriveBooking-Model {
        @include desktop {
            margin-inline-start: -10px;
        }
    }

    &-Dealership, &-Salutation {
        @include desktop {
            margin-inline-end: 10px;
        }

        .ShowroomBooking-Dealership,
        .ShowroomBooking-Salutation {
            margin-inline-end: 0;
        }
    }

    &-ContentWrapper,
    .TestdriveBooking-ContentWrapper {
        border-block-end: 1px dashed var(--separator-text-color);

        .ShowroomBooking,
        .TestdriveBooking {
            &-Heading {
                padding-block: 24px 36px;
                text-transform: none;

                @include desktop {
                    padding-block: 60px 44px;
                }
            }

            &-Description {
                font-size: 16px;
                padding-block-end: 33px;
            }

            &-Paragraph {
                line-height: 24px;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
