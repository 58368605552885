/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../../../geely/src/style/abstract/variables';
@import '../../../../../../../geely/src/style/abstract/media';
@import '../../../../../../../geely/src/style/abstract/button';
@import '../../../../../../../geely/src/style/abstract/loader';
@import '../../../../../../../geely/src/style/abstract/icons';
@import '../../../../../../../geely/src/style/abstract/parts';
@import '../../../../../../../geely/src/style/abstract/variables.override';
@import '../../../../../../../geely/src/style/abstract/button.override';
@import '../../../../../../../geely/src/style/abstract/media.override';
@import '../../../../../../../geely/src/style/abstract/parts.override';
@import '../../../../../../../geely/src/style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

.FieldMultiSelect-Select {
    height: 130px !important;
}
