.SeeAllModels {
    text-align: center;
    margin: 0 16px;

    @include desktop {
        margin: 0 auto;
        max-width: 816px;
    }

    &-Heading {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;

        @include desktop {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &-Text {
        margin: 12px 0 32px;
        font-size: 16px;
        line-height: 24px;
        color: var(--default-primary-silver-color);

        @include desktop {
            margin: 12px 0 40px;
        }
    }

    &-Button {
        h2 {
            font-size: 16px;
            line-height: 20px;
            margin: 0;
            color: var(--color-white);
            font-weight: 400;
        }
    }
}
