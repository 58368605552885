/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ResetButton {
    &-Button {
        width: initial;
    }

    &-Text {
        font-size: 12px;
        line-height: 18px;
        font-weight: 700;
        color: var(--default-primary-blue-color);
        cursor: pointer;

        &:hover {
            color: var(--default-primary-black-color);

            span {
                color: var(--default-primary-black-color);
            }
        }

        span {
            margin: 0 0 0 3px;
            color: var(--default-primary-blue-color);
        }
    }
}
