/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CheckoutBilling {
    &-Option {
        &_isChecked {
            border: 2px solid var(--default-primary-blue-color);
            height: 68px;
            margin: 1px 0 0;
        }
    }

    &-ActionBlock {
        .CheckoutBilling {
            &-ButtonPrevious {
                border: 1px solid var(--default-primary-black-color);
                background-color: var(--default-secondary-white-color);
                color: var(--default-primary-black-color);

                &:hover {
                    background-color: var(--default-primary-black-color);
                    color: var(--default-secondary-white-color);
                }
            }
        }
    }

    &-FormTab {
        .Field input {
            &:disabled {
                background-color: var(--disabled-color);
                color: var(--default-primary-silver-color);
            }
        }
    }
}

html[dir="rtl"] {
    .CheckoutBilling {
        &-Nationality, &-Salutation {
            margin-inline-start: 0;

            @include desktop {
                margin-inline-end: 5px;
            }
        }

        &-DocumentTab {
            .Field_type_file .FileError-Item .ErrorIcon {
                transform: scale(1.5) translateX(40%);
            }
        }
    }
}
