/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

/* stylelint-disable csstools/use-logical */

.StoreSwitcher {
    border-bottom: none;

    &-Title {
        font-size: 16px;
        font-weight: 700;
    }

    .ChevronIcon {
        margin-inline-start: 5px;

        [dir="rtl"] & {
            order: -1;
            margin-left: 5px;

            @include mobile {
                margin-left: 3px;
            }
        }

        @include mobile {
            margin-left: 3px;
            margin-top: 3px;
        }
    }

    &-StoreList {
        @include mobile {
            inset-inline-end: -90px;
        }

        .StoreItem-Item {
            min-width: 140px;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 20px;
            color: black;
            font-weight: 400;

            &:hover {
                color: var(--color-white);
                background-color: var(--secondary-blue-color);
            }

            &_isDisabled {
                color: var(--disabled-color);

                &:hover {
                    color: var(--disabled-color);
                    background-color: unset;
                }
            }
        }
    }

    &-SmallMobileStoreList {
        display: none;

        @include small-mobile {
            display: flex;
        }

        .StoreItem-Item {
            margin: 0;
            padding: 0;
            font-family: $font-HyundaiSansTextKR;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--disabled-color);

            &:hover {
                background-color: unset;
                cursor: pointer;
            }

            &_isActive {
                font-weight: 700;
                color: var(--color-black);
            }
        }
    }

    &-Separator {
        margin: 0 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--disabled-color);

        &:last-child {
            display: none;
        }
    }
}
