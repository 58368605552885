/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$arrowSvg: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 15L12 8L5 15' stroke='%23231815' stroke-width='2'/%3E%3C/svg%3E%0A");

.HomePage {
    .Header {
        position: initial;

        &-Wrapper {
            position: sticky;
            inset-block: 0;
            z-index: 1000;

            @include desktop {
                margin: 0;
            }
        }
    }

    .CmsPage {
        &-Wrapper {
            max-width: 100%;
            margin: 0;
            padding: 72px 0;

            @include desktop {
                padding: 80px 0;
            }
        }
    }

    .HowItWorks {
        &-ContentWrapper {
            padding: 0;
            margin: 72px 0;

            @include desktop {
                margin: 80px 0;
            }
        }

        &-Wrapper {
            margin: 0 auto;
            padding: 48px 16px;
            max-width: var(--content-wrapper-width);

            @include desktop {
                padding-block: 80px 136px;
            }
        }

        &-Accordion {
            &:first-child {
                .HowItWorks {
                    &-AccordionHeading {
                        border-radius: 6px 6px 0 0;
                    }
                }
            }

            &:last-child {
                .AccordionTabs {
                    &-TabName {
                        border-radius: 0 0 6px 6px;

                        &_isSelected {
                            border-radius: 0;

                            @include desktop {
                                border-radius: 6px 6px 0 0;
                            }
                        }

                        @include desktop {
                            border-radius: 6px 6px 0 0;
                        }
                    }
                }

                .HowItWorks {
                    &-AccordionContent {
                        border-radius: 0 0 6px 6px;
                    }
                }
            }

            &Wrapper {
                display: initial;
            }

            &Content {
                margin: 0;
                padding: 8px 24px 12px;

                @include desktop {
                    border-radius: 0 0 6px 6px;
                    padding: 40px 40px 48px 40px;
                }
            }

            &SubHeading {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                margin: 0 0 12px;
                padding: 0;

                @include desktop {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            &Text {
                font-size: 14px;
                line-height: 20px;
                color: var(--default-primary-silver-color);
                padding: 0;
            }

            &Image {
                margin: 12px -24px 0;
                width: calc(100% + 48px);

                @include desktop {
                    margin: 0;
                    position: absolute;
                    width: 43.7%;
                    inset-inline-end: 0;
                    inset-block-start: 60px;
                }
            }
        }

        li {
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);
            margin: 0 0 4px 20px;

            &::before {
                content: '';
                margin: 8px;
                padding: 0;
                height: 4px;
                width: 4px;
                background-color: var(--default-primary-blue-color);
            }

            &:last-child {
                margin: 0 0 0 20px;
            }
        }

        &-Heading {
            padding: 0;
            margin: 0;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            text-transform: uppercase;

            @include desktop {
                font-size: 36px;
                line-height: 48px;
            }
        }

        &-SubHeading {
            margin: 12px 0 24px;
            font-size: 16px;
            line-height: 24px;
            color: var(--default-primary-silver-color);
            text-align: center;

            @include desktop {
                margin: 12px 0 48px;
            }
        }

        &-TabHeading {
            margin: 4px 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include desktop {
                margin: 3px 0 0;
            }
        }
    }

    .AccordionTabs {
        &-ContentWrapper {
            width: initial;
        }

        &-TabsWrapper {
            flex-direction: row;
            column-gap: 2px;
        }

        &-TabContent {
            &_isWithImage {
                @include desktop {
                    padding-inline-end: 43%;
                }
            }
        }

        &-TabName {
            min-width: initial;
            background-color: var(--default-primary-grey-color);
            padding: 20px 24px 16px;
            color: var(--default-primary-silver-color);
            font-size: 16px;
            line-height: 24px;
            border: none;

            @include desktop {
                display: initial;
                width: 20%;
                margin: 0 0 2px;
                border-radius: 6px 6px 0 0;
            }

            &::after {
                background-image: $arrowSvg;
                width: 24px;
                height: 24px;
                inset-inline-end: 24px;
                inset-block: 32px;
            }

            b {
                color: var(--default-primary-black-color);
                font-size: 20px;
                line-height: 24px;
                text-transform: uppercase;
                font-family: var(--font-family-sans-pro);
                font-weight: 500;
            }

            &_isSelected {
                background-color: var(--color-white);
                margin: 0;
                align-items: flex-start;
                flex-direction: column;

                @include desktop {
                    border-block-end: 2px solid var(--color-white);
                }

                b {
                    color: var(--default-primary-blue-color);
                }
            }
        }
    }
}
