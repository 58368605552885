/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.Popup {
    &-Header {
        border: none;
    }

    &-HeaderWrapper {
        background-color: var(--default-primary-blue-color);
        padding: 16px 16px 12px;

        @include desktop {
            background-color: transparent;
        }
    }

    &-Heading {
        color: var(--color-white);
        font-weight: 500;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 24px;

        @include desktop {
            color: var(--default-primary-black-color);
        }
    }

    &-CloseBtn {
        .Popup & {
            @include mobile {
                inset-inline-end: 16px;
            }
        }

        path {
            stroke: var(--color-white);

            @include desktop {
                stroke: var(--default-primary-black-color);
            }
        }
    }

    &-ChildrenWrapper {
        min-height: 100%;

        &_hasCloseButton {
            min-height: calc(100% - 56px);
        }

        .CategoryFilterOverlay {
            margin: -20px -16px 0;
            padding: 0 0 52px;

            &-ResetButton,
            &-ApplyBtn {
                flex-grow: 1;
                padding: 12px 0 8px;
                line-height: 20px;
                font-size: 16px;
                font-family: var(--font-family-sans-pro);
                font-weight: 400;
                border-radius: 20px;

                &_isDisabled {
                    pointer-events: none;
                    background-color: var(--disabled-color);
                }
            }

            &-ResetButton {
                display: flex;
                justify-content: center;
                border: 1px solid var(--default-primary-black-color);

                &_isDisabled {
                    border: none;

                    .ResetButton {
                        &-Button {
                            .ResetButton {
                                &-Text {
                                    color: var(--color-white);

                                    span {
                                        color: var(--color-white);
                                    }
                                }
                            }
                        }
                    }
                }

                .ResetButton {
                    &-Text {
                        font-weight: 400;
                        font-size: 16px;
                        color: var(--default-primary-black-color);

                        span {
                            color: var(--default-primary-black-color);
                        }
                    }
                }
            }
        }
    }

    &-BottomCloseBtn {
        padding: 0 16px 16px;
        height: initial;
        background: none;
    }

    &-Button {
        line-height: 20px;
    }

    &.ProductStepsTab {
        &-Popup {
            &_isVisible {
                inset-block-start: var(--header-total-height);
            }

            .Popup {
                &-ChildrenWrapper {
                    padding: 0;
                }
            }
        }
    }
}
