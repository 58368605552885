/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';


$lowOpacity: 0.1;
$highOpacity: 1;

.Loader {
    &-PaymentMain {
        & path {
            &:nth-child(1) {
                fill: var(--stroke-color);
            }

            &:nth-child(2) {
                stroke: var(--secondary-blue-color);
                animation: loading-spinner 1500ms ease-in-out infinite;
                will-change: transform;
                transform-origin: 50% 50%;
            }
        }
    }

    .SkylineLoader {
        animation: skyline-heartbeat 2300ms ease-in-out infinite;
        -webkit-animation: skyline-heartbeat 2300ms ease-in-out infinite;
        -moz-animation: skyline-heartbeat 2300ms ease-in-out infinite;
        -o-animation: skyline-heartbeat 2300ms ease-in-out infinite;
        will-change: transform;
        transform-origin: 50% 50%;
        transform: scale(0.5) translateY(70%);
        background-image: $skylineshieldcolor;
        width: 120px;
        height: 164px;
    }
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes skyline-heartbeat {
    0% {
        opacity: $lowOpacity;
    }

    50% {
        opacity: $highOpacity;
    }

    100% {
        opacity: $lowOpacity;
    }
}

@-o-keyframes skyline-heartbeat {
    0% {
        opacity: $lowOpacity;
    }

    50% {
        opacity: $highOpacity;
    }

    100% {
        opacity: $lowOpacity;
    }
}

@-moz-keyframes skyline-heartbeat {
    0% {
        opacity: $lowOpacity;
    }

    50% {
        opacity: $highOpacity;
    }

    100% {
        opacity: $lowOpacity;
    }
}

@-webkit-keyframes skyline-heartbeat {
    0% {
        opacity: $lowOpacity;
    }

    50% {
        opacity: $highOpacity;
    }

    100% {
        opacity: $lowOpacity;
    }
}

@-o-keyframes skyline-heartbeat {
    0% {
        opacity: 10%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 10%;
    }
}

@-moz-keyframes skyline-heartbeat {
    0% {
        opacity: 10%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 10%;
    }
}

@-webkit-keyframes skyline-heartbeat {
    0% {
        opacity: 10%;
    }

    50% {
        opacity: 100%;
    }

    100% {
        opacity: 10%;
    }
}
