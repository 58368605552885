.GeelyOffers {
    margin: 60px auto 72px;
    max-width: 1920px;

    &-Slider {
        @include desktop {
            display: flex;
            justify-content: flex-end;
        }

        @include super-wide-desktop {
            width: initial;
        }

        .SliderWidget {
            height: 268px;

            @include tablet {
                height: 380px;
            }

            @include desktop {
                width: 600px;
                height: 432px;
                border-start-start-radius: 12px;
                border-end-start-radius: 12px;
                overflow: hidden;
            }

            @media (min-width: 1355px) {
                width: 676px;
            }

            @include super-wide-desktop {
                border-radius: 12px;
            }

            &-FigureImage {
                padding-block-end: 0;

                img {
                    width: 100%;
                    object-position: top;
                }
            }

            .Slider-Crumbs {
                position: absolute;
                inset-block-end: 24px;
                margin: 0;
            }
        }

        .Slider {
            &-Crumb {
                width: 10px;
                height: 10px;
                background-color: var(--color-white);
                will-change: background-color;
                transition: background-color 200ms;

                &_isActive {
                    background-color: var(--default-primary-blue-color);
                }
            }
        }
    }

    &-ContentWrapper {
        padding: 24px 16px 0;

        @include desktop {
            padding-inline-start: 100px;
            padding-inline-end: 36px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-end;
        }

        @include super-wide-desktop {
            flex-grow: 1;
            max-width: 1224px;
            padding: 0 80px 0 100px;
        }
    }

    &-Heading {
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        text-transform: uppercase;
        margin: 0 0 12px;

        @include desktop {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &-Paragraph {
        font-size: 16px;
        line-height: 24px;
        color: var(--default-primary-silver-color);

        &:last-of-type {
            margin: 0 0 24px;

            @include desktop {
                margin: 0 0 40px;
            }
        }
    }

    &-Button {
        h2 {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: 20px;
            margin: 0;
            padding: 0;
        }
    }

    .pagebuilder-column-group {
        .pagebuilder-column {
            order: 2;

            @include desktop {
                order: 1;
            }

            &:last-child {
                order: 1;

                @include desktop {
                    order: 2;
                }
            }
        }
    }
}
