/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --secondary-hover-color: #074EDE;
}

.MyVehicles {
    background-color: var(--surface-color);

    @include mobile {
        margin-block-end: 0;
    }

    &-Popup {
        .Popup {
            &-Content {
                min-width: 450px;
            }

            &-Header {
                padding: 26px;
            }

            &-ChildrenWrapper {
                text-align: center;
                padding: 44px 48px 92px 48px;
            }
        }
    }

    &-Wrapper {
        min-height: calc(100vh - var(--header-height) - var(--footer-content-height));
        max-width: var(--content-wrapper-width);
        margin: 0 auto;
        padding-block-end: 12px;

        @include desktop {
            padding-block-end: 68px;
        }
    }

    .CloseIcon {
        width: 18px;
        height: 18px;
    }

    &-Header {
        padding: 24px 16px 36px 16px;

        @include desktop {
            padding: 44px 52px;
        }
    }

    &-Label {
        position: absolute;
        inset-block-start: 16px;
        inset-inline-start: -8px;

        @include desktop {
            inset-block-start: 20px;
            inset-inline-start: -20px;
        }
    }

    &-NoOrdersContent {
        min-height: calc(100vh - var(--header-height) - var(--footer-content-height));
        padding: 0 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &-NoOrdersMessage {
        font-size: 22px;
        line-height: 35px;
        font-weight: 700;
        max-width: 600px;
        text-align: center;

        @include mobile {
            max-width: 310px;
        }
    }

    &-NoOrdersButton {
        max-width: 350px;
    }

    .MyVehicles-Pod-ProductInfo {
        li::before {
            padding: 0;
            inset-inline: 0;
        }
    }
}
