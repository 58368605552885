/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CarouselScrollArrow {
    $arrow-size: 25px;
    --arrow-position: 12px;

    position: absolute;
    inset-inline-start: var(--arrow-position);
    inset-inline-end: unset;
    cursor: pointer;
    mix-blend-mode: exclusion;

    &_isNextArrow {
        inset-inline-end: var(--arrow-position);
        inset-inline-start: unset;
    }
}
