/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$triangleSvg: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.29289 0.707106C6.68342 0.316582 7.31658 0.316583 7.70711 0.707107L14 7H0L6.29289 0.707106Z' fill='%23F4F5F7'/%3E%3C/svg%3E%0A");

.StoreSwitcher {
    margin: 0;

    &-Title {
        text-transform: uppercase;
        line-height: 24px;
    }

    .ChevronIcon {
        width: 16px;
        height: 16px;
        margin: 0 0 4px 9px;
        margin-inline-end: 0;

        @include desktop {
            margin: 0 0 4px 11px;
        }
    }

    &-StoreList {
        border: none;
        border-radius: 6px;
        background-color: var(--default-primary-light-grey-color);
        inset-inline-end: -21px;
        inset-block-start: 30px;

        &::after {
            content: $triangleSvg;
            position: absolute;
            width: 14px;
            height: 7px;
            inset-block-start: -10px;
            inset-inline-end: 22px;
        }

        &_isInverted {
            inset-block-end: calc(100% + 10px);
            inset-block-start: initial;

            &::after {
                inset-block-start: unset;
                inset-block-end: -10px;
                transform: rotate(180deg);
            }
        }
    }

    .StoreItem {
        &-Item {
            font-family: var(--font-family-sans-pro);
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);
            padding: 12px 20px;
            cursor: pointer;
            min-width: 117px;
            border-block-end: 1px solid var(--color-white);

            &:hover {
                color: var(--default-primary-silver-color);

                .StoreItem-RadioButton {
                    background-color: var(--default-primary-light-grey-color);

                    &_isActive {
                        background-color: var(--color-white);
                    }
                }
            }

            &:last-of-type {
                border-block-end: none;
            }
        }
    }
}
