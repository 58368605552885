.GeelyInsurance {
    &-WhatsIncluded {
        margin-block-end: 20px;
    }

    &-Header {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 0 0 8px;
    }

    &-Paragraph {
        margin: 0 0 24px;
        font-size: 14px;
        line-height: 20px;
        color: var(--default-primary-silver-color);
    }

    &-List {
        li {
            list-style-type: square;
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);
            list-style-position: outside;
            margin-inline-start: 16px;

            &::before {
                display: none;
            }

            &::marker {
                color: var(--default-primary-blue-color);
            }
        }
    }

    &-Items {
        padding: 8px 0 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        @include desktop {
            display: flex;
            justify-content: center;
        }
    }

    &-Item {
        max-width: 132px;
        text-align: center;
        margin: 0 auto;

        &:first-child {
            @include desktop {
                margin: 0 auto 0 0;
            }
        }

        @include desktop {
            max-width: 100px;
        }
    }

    &-Icon {
        margin: 0 0 8px;

        > * {
            stroke: var(--primary-base-color);
        }
    }

    &-ItemText {
        font-size: 14px;
        line-height: 20px;
        color: var(--default-primary-silver-color);
    }
}
