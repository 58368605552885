/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.CarouselScrollItem {
    width: calc(var(--carousel-width) / 5 - var(--carousel-scroll-gap));
    height: 100%;
    border: none;
    filter: none;

    .Image, img {
        border-radius: 6px;
    }

    >.ProductGalleryThumbnailImage {
        -webkit-transition: var(--animation-speed) -webkit-filter linear;
        -moz-transition: var(--animation-speed) -moz-filter linear;
        -moz-transition: var(--animation-speed) filter linear;
        -ms-transition: var(--animation-speed) -ms-filter linear;
        -o-transition: var(--animation-speed) -o-filter linear;
        transition: var(--animation-speed) filter linear, var(--animation-speed) -webkit-filter linear;
        padding: 0;
        border-radius: 6px;
        filter: brightness(0.7);

        &:focus,
        &:hover {
            filter: brightness(1);
        }
    }

    &_isActive {
        .ProductGalleryThumbnailImage {
            filter: brightness(1);
        }

        filter: none;
    }

    &:focus,
    &:hover {
        filter: none;
    }

    &-SlideNumber {
        position: absolute;
        color: var(--default-secondary-white-color);
        font-family: var(--font-family-sans-pro);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        inset-block-start: 35%;
        inset-inline-start: 40%;
        z-index: 2;
        display: block;
    }
}
