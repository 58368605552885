$whyBuyFromGradient: linear-gradient(
    207deg,
    rgba(35, 24, 21, 0.5) 16.64%,
    rgba(35, 25, 22, 0.494466) 24.11%,
    rgba(34, 27, 26, 0.477274) 31.59%,
    rgba(32, 30, 32, 0.447738) 39.07%,
    rgba(30, 35, 41, 0.405657) 46.54%,
    rgba(27, 41, 53, 0.351664) 54.02%,
    rgba(24, 49, 67, 0.387594) 61.49%,
    rgba(20, 57, 82, 0.316678) 68.97%,
    rgba(16, 65, 98, 0.243322) 76.44%,
    rgba(12, 73, 113, 0.172406) 83.92%,
    rgba(9, 81, 127, 0.168336) 91.4%,
    rgba(6, 87, 139, 0.154343) 98.87%,
    rgba(4, 92, 148, 0.112262) 106.35%,
    rgba(2, 95, 154, 0.082726) 113.82%,
    rgba(1, 97, 158, 0.065534) 121.3%,
    rgba(1, 98, 159, 0.06) 128.78%
);

.WhyBuyFrom {
    &-BackgroundImage {
        &::after {
            content: '';
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0;
            width: 100%;
            height: 100%;
            display: block;
            background: $whyBuyFromGradient;
        }
    }

    &-Image {
        display: none;
        object-fit: cover;
        position: absolute;
        height: 100%;

        @include desktop {
            display: block;
            height: 460px;
            position: relative;
        }

        &_isMobile {
            display: block;

            @include desktop {
                display: none;
            }
        }
    }

    &-Content {
        width: 100%;
        inset-block-start: 0;
        padding: 48px 16px;
        z-index: 1;

        @include tablet {
            padding: 80px 100px;
            text-align: center;
        }

        @include narrow-desktop {
            padding: 80px 100px;
            text-align: center;
        }

        @include desktop {
            padding: 80px 0 0;
            text-align: center;
            position: absolute;
        }
    }

    &-Heading {
        color: var(--color-white);
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
        text-transform: uppercase;

        @include desktop {
            font-size: 36px;
            line-height: 48px;
        }
    }

    &-Text {
        color: var(--default-primary-grey-color);
        font-size: 14px;
        line-height: 20px;
        max-width: 818px;
        margin: 12px auto 40px;
    }

    &-Button {
        max-width: 818px;

        h3 {
            font-weight: inherit;
            color: inherit;
            font-size: inherit;
            line-height: 20px;
            padding: 0;
            margin: 0;
        }
    }

    &-BenefitsBlock {
        display: flex;
        flex-direction: column;
        padding: 16px 16px 0 16px;
        row-gap: 16px;

        @include desktop {
            flex-direction: row;
            justify-content: center;
            column-gap: 32px;
            margin-block-start: -96px;
        }
    }

    &-Benefit {
        text-align: center;
        padding: 32px 24px;
        background-color: var(--default-primary-light-grey-color);
        border-radius: 6px;

        @include desktop {
            max-width: 286px;
        }
    }

    &-Icon {
        height: 61px;

        path {
            stroke: var(--primary-base-color);
        }
    }

    h3 {
        &.WhyBuyFrom-SubHeading {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &-SubHeading {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 24px 0 8px;
    }

    &-Paragraph {
        color: var(--default-primary-silver-color);
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }
}
