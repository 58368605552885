/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

.ServiceBooking {
    @include mobile {
        margin-block-start: 0;
    }

    &-Wrapper {
        .FieldForm {
            &-Fields {
                input, select {
                    &:disabled {
                        color: var(--disabled-color);
                    }
                }
            }
            
            &-Body { 
                .FieldGroup {
                    &-Wrapper {
                        @include desktop {
                            padding-inline: 40px;
                            border-radius: 6px;
                        }

                        &:not(:first-child) {
                            margin-block-start: 1px;

                            @include desktop {
                                margin-block-start: 4px;
                            }
                        }
        
                        &_vehicle {
                            @include desktop {
                                border-radius: 6px 6px 0 0;
                            } 
                        }
        
        
                        &_concent {
                            @include desktop {
                                border-radius: 6px 6px 0 0;
                            }
                        }
                    }

                    &-Label {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24px;
                    }

                    .Field {
                        &-Wrapper {
                            .Field {
                                @include mobile {
                                    margin-block-start: 16px;
                                }

                                &-Label {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                }
                            }

                            &_type_email {
                                .Field { 
                                    &-SubLabelText {
                                        color: var(--default-primary-silver-color);
                                        margin-inline-start: 24px;
        
                                        &::before {
                                            background-image: url('../../style/icons/icon-info-gray.svg');
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }
                            }

                            input {
                                &:disabled {
                                    &::placeholder {
                                        opacity: .4;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &_isSecondStep {
        @include mobile {
            margin-block-start: 0;
        }

        .FieldGroup {
            &-Wrapper {
                &_vehicle {
                    @include desktop {
                        border-radius: 6px;
                    }
                }
            }
        }
    }

    &-SubmitWrapper {
        border-radius: 0 0 6px 6px;

        @include desktop {
            padding-inline: 40px;
        }

        @include mobile {
            text-align: center;
        }
    }

    &-NextButton {
        @include desktop {
            width: 196px;
        }
    }

    &-SubmitButton {
        @include desktop {
            width: unset;
            max-width: 50%;
        }
    }

    .ServiceBooking-PayLaterButton {
        @include desktop {
            width: 196px;
        }

        background-color: transparent;
        border: 1px solid var(--default-primary-black-color);
        color: var(--default-primary-black-color);

        &:hover {
            background-color: var(--button-hover-background) !important;
            color: var(--button-hover-color) !important;
        }
    }

    &-PrefTime {
        .FieldSelect {
            &-Option, &-Select {
                direction: ltr;

                [dir="rtl"] & {
                    /* stylelint-disable-next-line csstools/use-logical */
                    text-align: right;
                }
            }
        }
    }

    &-ConsentPopup {
        .Popup {
            &-ChildrenWrapper {
                @include desktop {
                    padding: 60px 40px 40px;
                }

                padding: 48px 0 0;
            }

            &-Content {
                max-width: 689px;
                border-radius: 6px;

                @include mobile {
                    max-width: 100vw;
                    position: absolute;
                    inset-block-end: 0;
                }
            }

            &-Header {
                position: absolute;
                inset-inline-end: 0;
                border-radius: 6px;
                background-color: transparent;

                @include mobile {
                    padding-block-start: 16px;
                }
            }

            &-HeaderWrapper {
                background-color: var(--surface-color);
            }

            &-CloseBtn {
                path {
                    stroke: var(--body-content-color);
                }
            }
        }

        .ServiceConsentPopup {
            @include mobile {
                padding: 0 16px 0;
            }

            &-Title {
                text-transform: uppercase;
                margin-block-start: 0;
                font-weight: 500;
            }

            &-ListItem {
                margin-block-end: 20px;

                &::before {
                    position: relative;
                    inset-inline-start: 0;
                    padding-inline-end: 0;
                }

                &:last-child {
                    margin-block-end: 32px;
                }
            }

            &-SubmitWrapper {
                display: flex;
                flex-direction: column;
                gap: 12px;

                @include mobile {
                    padding: 0 16px 16px;
                }

                @include desktop {
                    flex-direction: row;
                    gap: 20px;
                }
            }

            &-CloseButton {
                height: 40px;

                @include mobile {
                    order: 2;
                    width: 100%;
                }

                &:hover {
                    background-color: var(--button-background);
                    border-color: var(--button-background);
                    color: var(--button-color);
                }
            }

            &-SubmitButton {
                height: 40px;

                @include mobile {
                    order: 1;
                }
            }

            &-SubmitButton, &-CloseButton {
                &:hover {
                    height: 40px;
                    border: none;
                }
            }
        }
    }
}
