/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ResetAttributes {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 16px 0;

    &-AttributeValue {
        padding : 6px 8px 6px;
        background-color: var(--color-white);
        width: fit-content;
        border-radius: 6px;
    }

    &-AttributeText {
        inset-inline-start: 0;
        color: var(--default-primary-dark-grey-color);
    }

    &-AttributeLabel {
        font-weight: 400;
    }

    &-CloseIcon {
        height: 12px;
    }

    .CloseIcon {
        width: 12px;
        height: 12px;
        margin-block: 0;
        margin-inline: 12px 0;
    }
}
