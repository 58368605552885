/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.StateLabel {
    padding-block: 8px;
    padding-inline: 21px 27px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 10px) 100%, 0 100%);
    color: var(--color-white);
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    background-color: var(--secondary-blue-color);

    &_status {
        &_new {
            background-color: var(--secondary-orange-color);
        }

        &_closed,
        &_complete,
        &_canceled,
        &_denied {
            background-color: var(--disabled-color);
        }
    }
}

[dir="rtl"] .StateLabel {
    clip-path: polygon(0 0, 10px 100%, 100% 100%, 100% 0);
}
