/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ShowroomBooking {
    padding-block-end: 109px;

    &-Submit {
        @include desktop {
            max-width: 196px;
        }

        &-Wrapper {
            text-align: center;
            margin-block-end: 0;
        }
    }

    &-Wrapper {
        .FieldForm {
            &-Fields {
                select {
                    &:disabled {
                        color: var(--text-placeholder-color);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .TestdriveBooking {
        &-Model {
            .FieldSelect-Options {
                &::-webkit-scrollbar {
                    display: initial;
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    display: initial;
                    background: var(--scroll-thumb-color);
                    border-radius: 6px;
                }
            }
        }
    }
}
