/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

@function escape-color($color) {
    @if str-slice('#{$color}',1, 1) != "#" {
        @return $color;
    }

    @return '%23' + str-slice('#{$color}', 2, -1)
}

@mixin toggle-chevron($color: #000) {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none"><path d="M15 1L7.729 8L1 1" stroke="#{escape-color($color)}" stroke-width="2"/></svg>');
}

.AccordionTabs {
    &-TabContent {
        display: none;
        // for priority
        @include desktop {
            display: none;
        }

        &_isVisible {
            display: block;
        }
    }

    &-TabName {
        &::after {
            content: '';
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 14px;
            height: 7px;
            position: absolute;
            inset-inline-end: 17px;
            will-change: transform;
            transition: transform 300ms;

            @include toggle-chevron(#FFF);
        }

        &_isDarkChevron {
            &::after {
                @include toggle-chevron(#000);
            }
        }

        &_isSelected {
            &::after {
                @include toggle-chevron(#000);

                transform: rotate(180deg);
            }
        }
    }
}
