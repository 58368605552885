/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --popup-padding: 16px;
    --popup-desktop-padding: 40px 48px;
    --popup-content-padding: var(--popup-padding);

    @include mobile {
        --popup-content-padding: var(--popup-padding);
    }

    @include desktop {
        --popup-content-padding: var(--popup-desktop-padding);
    }
}

.Popup {
    &-Content {
        border-radius: 0;
        padding: 0;

        @include desktop {
            max-height: unset;
            overflow-y: unset;
        }

        &::after {
            display: none;
        }
    }

    &-ChildrenWrapper {
        padding: 20px 16px;

        @include scrollbar;

        @include desktop {
            max-height: 65vh;
            overflow-y: auto;
            padding: 40px 48px;
        }
    }

    &-Header {
        z-index: 400;

        @include desktop {
            position: sticky;
            inset-block-start: 0;
            align-items: center;
            display: flex;
            padding: var(--popup-desktop-padding);
            background-color: var(--color-white);
        }

        @include mobile {
            position: fixed;
            inset-block-start: 0;
            display: block;
            background-color: var(--background-color);
            width: 100%;
        }

        &_hasTitle {
            border-block-end: 1px solid var(--stroke-color);
        }
    }

    &-HeaderWrapper {
        @include desktop {
            width: 100%;
        }

        @include mobile {
            padding: var(--popup-padding) 20px;
        }
    }

    &-Heading {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        margin-block-end: 0;

        @include desktop {
            font-size: 30px;
            line-height: 32px;
        }
    }

    .Popup-CloseBtn {
        display: flex;
        align-items: center;
        inset-block-start: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;

        @include mobile {
            inset-inline-end: var(--popup-padding);
        }

        .CloseIcon {
            transform: scale(1.2);
        }
    }

    @include mobile {
        &.ProductStepsTab-Popup {
            &_isVisible {
                inset-block-start: 0;
            }
        }

        &-BottomCloseBtn {
            position: fixed;
            inset-block-end: 0;
            width: 100vw;
            height: 60px;
            background-color: #0e306c;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                span {
                    font-size: 22px;
                    line-height: 28px;
                    font-weight: 700;
                    color: white;
                }
            }
        }
    }
}
