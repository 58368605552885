/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

$labelBackground: url("data:image/svg+xml,%3Csvg width='320' height='24' viewBox='0 0 320 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='vector' d='M0 0H320V24H4.5L0 0Z' fill='%23FF9934'/%3E%3C/svg%3E%0A");

:root {
    --discount-label-width: 320px;
    --discount-label-height: 24px;
    --icon-margin: 28px;
    --neutrals-disclaimer-disable: #999;
    --neutrals-stroke: #D9D9D9;
    --neutrals-surface: #f4f4f4;
    --success-color: #00AF87;
    --button-apply-height: 48px;
    --margin-height: 12px;
    --input-side-padding: 20px;
}

.ServicePayment {
    &-Wrapper {
        width: 100%;
    }

    &-Radio {
        &Wrapper {
            cursor: pointer;
            margin-block-start: 16px;
            width: 100%;
            border: 1px solid var(--neutrals-stroke);
            padding: var(--input-side-padding);
            display: flex;
            flex-direction: column;
            gap: 16px;
            text-align: start;

            .Field {
                &-Wrapper {
                    .Field {
                        /* stylelint-disable-next-line declaration-no-important */
                        margin: 0 !important;

                        input {
                            height: 24px;
                        }
                    }
                }
            }

            @include desktop {
                width: calc(50% - 10px);
                max-width: 432px;
                margin-block-start: 0;
            }
            
            &_checked {
                border: 1px solid var(--secondary-blue-color);
                outline: 1px solid var(--secondary-blue-color);
            }
        }

        &Row {
            display: flex;
            padding: 0;
            justify-content: space-between;
            width: 100%;
        }

        &SubLabel {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &-DiscountLabel {
        &Wrapper {
            width: var(--discount-label-width);
            height: var(--discount-label-height);
            inset-block-start: calc(-1 * (var(--discount-label-height) / 2));
            inset-inline-end: var(--input-side-padding);
            position: absolute;
            background-color: transparent;
            background-image: $labelBackground;
            background-repeat: no-repeat;
            object-fit: cover;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &Text {
            color: black;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-wrap: nowrap;
        }
    }

    &-Discount {
        &FullPrice {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: black;
            text-wrap: nowrap;
        }

        &ReducedPrice {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: var(--secondary-orange-color);
            text-wrap: nowrap;
        }

        &Wrapper {
            display: flex;
            gap: 8px;

            &_hasDiscount {
                .ServicePayment-Discount {
                    &FullPrice {
                        text-decoration-line: line-through;
                        color: var(--neutrals-disclaimer-disable);
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &-Coupon {
        &Clear {
            position: absolute;
            display: block;
            height: 16px;
            width: 16px;
            background-image: url('../../style/icons/icon_popup_close.svg');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            inset-block-end: calc(var(--button-apply-height) + var(--margin-height) + 16px);
            inset-inline-end: var(--input-side-padding);

            @include desktop {
                inset-inline-start: calc(50% - 42px);
                inset-block-end: 16px;
            }
        }

        &Wrapper {
            width: 100%;
        }

        &InputRow {
            display: flex;
            gap: 12px;
            align-items: flex-end;
            flex-direction: column;

            @include desktop {
                flex-direction: row;
            }

            input {
                &:focus {
                    border: 1px solid var(--neutrals-stroke);
                }
            }

            .Field-Wrapper {
                @include mobile {
                    width: 100%;

                    .Field_type_button {
                        /* stylelint-disable-next-line declaration-no-important */
                        margin-block-start: 0 !important;
                    }
                }

                &_type_button {
                    button {
                        height: var(--button-apply-height);
                        width: 100%;
                        padding: 18px 24px;
                        color: black;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        font-family: $font-HyundaiSansTextKR;
                        background-color: var(--neutrals-surface);
                        border: 1px solid var(--neutrals-stroke);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
    
                        @include desktop {
                            width: 150px;
                        }
                    }
                }
            }
        }

        &MessagesRow {
            margin-block-start: 12px;
            margin-inline-start: var(--icon-margin);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;
        }

        &Error, &Success, &Info {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        &Error {
            color: var(--primary-error-color);

            &::before {
                content: '';
                display: block;
                height: 20px;
                width: 20px;
                background-image: url('../../style/icons/icon-error.svg');
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                inset-inline-start: calc(-1 * var(--icon-margin));
            }
        }

        &Success {
            color: var(--success-color);

            &::before {
                content: '';
                display: block;
                height: 20px;
                width: 20px;
                background-image: url('../../style/icons/checkmark-icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                inset-inline-start: calc(-1 * var(--icon-margin));
            }
        }

        &Info {
            &_isOutside {
                margin-inline-start: var(--icon-margin);
                margin-block-start: var(--margin-height);
            }

            color: var(--secondary-blue-color);

            &::before {
                content: '';
                display: block;
                height: 20px;
                width: 20px;
                background-image: url('../../style/icons/icon-info-blue.svg');
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                inset-inline-start: calc(-1 * var(--icon-margin));
            }
        }

        &Total {
            input {
                border: none;
                /* stylelint-disable-next-line declaration-no-important */
                color: black !important; 
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }
    }

    &-Label {
        width: 100%;
        margin: 24px 0 8px 0;

        @include desktop {
            margin: 24px 0;
        }

        &Text {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }
    }
}
