/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --menu-item-color-desktop: var(--default-primary-blue-color);
}

.Menu {
    &-MenuWrapper {
        height: 100%;
        border-inline-end: none;
        border: none;
        margin: 0;
        padding-inline-start: 40px;
        background-color: var(--default-secondary-blue-color);
    }

    &-Item {
        height: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include desktop {
            &:hover {
                border-block-end: 4px solid var(--menu-item-color-desktop);
                padding: 4px 0 0;
            }
        }

        &Caption {
            font-size: 16px;
            font-weight: 500;
            color: var(--menu-item-color-desktop);
            text-transform: uppercase;

            @include desktop {
                line-height: inherit;
                margin-block-start: 4px;
            }

            &:hover {
                color: var(--menu-item-color-desktop);
            }
        }

        .Menu {
            &-ItemCaption {
                &_type {
                    &_main {
                        text-align: center;
                    }
                }
            }
        }
    }

    &-ItemList {
        height: 100%;
        column-gap: 60px;
    }

    &-MainCategories {
        height: 100%;
    }
}

.MobileMenuOverlay {
    &.Overlay {
        inset-inline-end: 0;
        z-index: 100;
        background-color: var(--default-secondary-blue-color);

        &.Overlay_isVisible {
            inset-inline-start: initial;
            width: calc(100vw - 32px - var(--header-logo-width));
        }

        .Menu {
            &-MenuWrapper {
                justify-content: flex-end;
            }

            &-MainCategories {
                margin: 0;
            }

            &-ItemCaption {
                font-family: var(--font-family-sans-pro);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                border-block-end: 4px solid transparent;

                &:hover {
                    border-block-end: 4px solid white;
                }

                &_type_main {
                    text-align: end;
                }
            }

            &-Item {
                margin: 20px 0 0;

                &:first-child {
                    margin: 0;
                }
            }
        }

        .MenuPage {
            background: none;
            padding: 76px 16px 0 16px;
        }

        .MenuOverlay {
            &-CloseButton {
                svg {
                    width: initial;
                    height: initial;

                    path {
                        stroke: var(--default-primary-blue-color);
                    }
                }
            }
        }
    }
}
