/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

@font-face { // Light
    font-family: 'OpenSans';
    src: url(../../style/fonts/OpenSansLight.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face { // Regular
    font-family: 'OpenSans';
    src: url(../../style/fonts/OpenSansRegular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face { // Medium
    font-family: 'OpenSans';
    src: url(../../style/fonts/OpenSansSemiBold.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face { // Bold
    font-family: 'OpenSans';
    src: url(../../style/fonts/OpenSansBold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}
