/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--stroke-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, var(--default-primary-blue-color));
    --checkbox-border-radius: var(--imported_checkboxes_border_radius, 4px);
}

.Field {
    font-family: var(--font-family-sans-pro);

    input, select {
        font-size: 14px;
    }

    input, select, textarea {
        &:disabled,
        &::placeholder {
            color: var(--text-placeholder-color);
            opacity: 1;
        }
    }

    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border-color: var(--stroke-color);

                    &:focus {
                        border-color: unset;
                    }
                }
            }
        }
    }

    .FieldFile {
        &-SubLabelText {
            color: var(--default-primary-dark-grey-color);
        }
    }

    .CheckmarkIcon-Icon {
        position: absolute;
        inset-block-end: 15px;
        inset-inline-end: 20px;
    }

    &_type_password {
        &.Field_isValid {
            .CheckmarkIcon-Icon {
                position: absolute;
                inset-block-end: 15px;
                inset-inline-end: 56px;
            }
        }
    }


    [type='checkbox'] {
        &:checked {
            + .input-control {
                border-color: var(--checkbox-border-color-active);
                background-color: var(--default-primary-blue-color);

                .RadioIcon {
                    --checkmark-color: var(--default-secondary-white-color);
                }
            }

            &:hover + .input-control {
                @include desktop {
                    border-color: var(--checkbox-border-color-active);
                    background-color: var(--default-primary-blue-color);
                }
            }

            &:disabled {
                &:hover + .input-control {
                    @include desktop {
                        background-color: var(--surface-color);
                        border-color: var(--checkbox-border-color);
                    }
                }

                + .input-control {
                    border-color: var(--checkbox-border-color);
                    background-color: var(--surface-color);
                    cursor: auto;
                    opacity: .7;

                    .RadioIcon path {
                        --checkmark-color: var(--checkbox-border-color);
                    }
                }
            }
        }
    }
}
