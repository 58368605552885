/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProgressBar {
    background-color: var(--default-primary-light-grey-color);
    border-radius: $borderRadius $borderRadius 0 0;
    padding: 16px 16px 0;
    position: sticky;
    inset-block-start: 0;

    @include desktop {
        padding: 20px 24px;
    }

    &-Header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-block-end: 8px;
    }

    &-Label {
        text-transform: uppercase;
        color: var(--default-primary-blue-color);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
    }

    &-Steps {
        margin: 0;
        font-family: var(--font-family-sans-pro);
        color: var(--default-primary-blue-color);
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    &-TotalSteps {
        color: var(--default-primary-black-color);
        font-size: 16px;
    }

    &-Breadcrumb {
        width: 100%;
        height: 4px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 4px;
    }

    &-BreadcrumbItem {
        height: 100%;
        flex-grow: 1;
        background-color: var(--default-primary-grey-color);
        border-radius: 2px;
        transition: all 0.2s ease-in-out;

        &_isActive {
            background-color: var(--default-primary-blue-color);
        }
    }
}
