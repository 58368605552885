/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.Breadcrumb {
    $crumb-padding: 6px;

    display: inline-block;
    padding-inline-end: 8px;
    margin-block-end: 0;
    height: 20px;
    font-size: 14px;
    font-weight: 400;

    .ChevronIcon {
        filter: none;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-inline-start: 0;
        fill: none;

        @include desktop {
            width: 12px;
            height: 12px;
            inset-inline-start: 2px;
            inset-block-end: -2px;
            margin-inline-start: $crumb-padding;
        }

        path {
            stroke: var(--default-primary-black-color);
        }
    }

    &:last-child {
        margin: 0;

        .ChevronIcon {
            @include desktop {
                display: none;
            }
        }

        .Breadcrumb {
            &-Link {
                color: var(--default-primary-black-color);

                @include desktop {
                    text-transform: uppercase;
                }
            }
        }

        :link {
            pointer-events: auto;

            @include desktop {
                pointer-events: none;
            }
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        text-transform: capitalize;
        color: var(--breadcrumbs-color);
        display: flex;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        @include desktop {
            display: inline-block;
        }

        &:hover {
            color: var(--link-color);
        }

        span {
            color: inherit;
            margin-inline-start: 2px;
            font-family: var(--font-family-sans-pro);
            font-size: 12px;
            line-height: 18px;
            margin-block-start: 2px;
        }
    }
}
