/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --header-logo-height: 32px;
    --header-logo-width: 68px;
    --header-nav-height: 52px;
    --header-height: 52px;
    --header-icon-size: 20px;

    @include desktop {
        --header-logo-width: 194px;
        --header-nav-height: 72px;
        --header-height: 72px;
        --header-icon-size: 24px;
    }
}

.Header {
    border: none;
    font-family: var(--font-family-sans-pro);
    color: var(--default-secondary-blue-color);
    position: fixed;
    inset-block-end: 0;

    &-Nav {
        background-color: var(--default-secondary-blue-color);
        padding: 0;

        @include desktop {
            background-color: var(--default-secondary-blue-color);
        }

        svg {
            path {
                /* stylelint-disable-next-line declaration-no-important */
                stroke: var(--default-primary-blue-color) !important;
            }

            .UserIconCheckMark {
                /* stylelint-disable-next-line declaration-no-important */
                stroke: white !important;
            }
        }
    }

    &-Welcome {
        margin-inline-end: 0;
    }

    &-MenuSpace {
        flex-grow: 1;
        height: 100%;
        background-color: var(--default-secondary-blue-color);
    }

    &-LogoWrapper {
        display: flex;
        align-items: center;
        margin: 0 16px;

        @include desktop {
            margin: 0 43px;
        }

        &Block {
            display: flex;
            height: 100%;
            background-color: var(--color-white);
            align-items: center;
        }
    }

    .Logo {
        height: var(--header-logo-height);
        width: var(--header-logo-width);
    }

    &-MyAccountContainer {
        margin-inline-end: 0;
        margin-inline-start: 25px;
        margin: 0 0 0 25px;

        @include desktop {
            margin-inline-end: 40px;
            margin: 0 40px 0 20px;
        }
    }

    &-MyVehiclesContainer {
        display: flex;
        margin-inline-start: 24px;

        @include desktop {
            margin-inline-start: 20px;
        }
    }

    .StoreSwitcher {
        margin-inline-start: 16px;

        @include desktop {
            margin-inline-start: 40px;
        }

        &-Title {
            color: var(--default-primary-blue-color);
            margin-block-start: 4px;
        }

        .ChevronIcon {
            order: 2;
        }
    }

    .MyCarIcon,
    .UserIcon,
    .MenuIcon {
        width: var(--header-icon-size);
        height: var(--header-icon-size);
    }

    &-MyVehiclesContainer,
    &-MyAccountContainer,
    &-MenuButton {
        height: var(--header-icon-size);
        width: var(--header-icon-size);
    }

    &-MenuButton {
        margin-inline-start: 24px;
        margin: 0 16px 0 24px;
    }

    &-MenuOverlayButtonWrapper {
        margin-inline-start: 0;
    }

    &-AccountDropdownWrapper {
        position: absolute;
        width: 116px;
        border-radius: 6px;
        background-color: var(--default-primary-light-grey-color);
        inset-inline-end: -10px;
        inset-block-start: 35px;
        transition: 0.2s ease-in;

        &::after {
            content: '';
            background-image: $accountTriangle;
            height: 7px;
            width: 14px;
            position: absolute;
            inset-block-start: -7px;
            inset-inline-end: 15px;

            @include mobile {
                inset-block-start: -6px;
            }
        }

        &_isInverted {
            inset-block-start: unset;
            inset-block-end: 40px;

            &::after {
                inset-block-start: unset;
                inset-block-end: -7px;
                transform: rotate(180deg);

                @include mobile {
                    inset-block-end: -6px;
                }
            }
        }
    }

    &-AccountDropdownItem {
        color: var(--default-primary-silver-color);
        font-family: var(--font-family-sans-pro);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        height: 44px;
        cursor: pointer;
        text-align: start;
        border-bottom: 1px solid var(--default-secondary-white-color);
        padding: 0 12px;

        &:last-child {
            border-bottom: none;
        }
    }
}
