:root {
    --link-color: var(--imported_link_color, var(--hover-color));
    --link-hover: var(--imported_linkHover_color, var(--secondary-blue-color));
    --default-hover-link-underline: none;
    --link-underline: var(--imported_link_border_bottom, var(--default-hover-link-underline));
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    font-size: 16px;
    line-height: 24px;

    &:hover {
        color: var(--link-hover);
    }

    &.Link {
        &:hover,
        &:focus {
            padding-block-end: 0;
            text-decoration: none;
        }
    }
}
