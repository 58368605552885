/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../../../geely/src/style/abstract/variables';
@import '../../../../../../../geely/src/style/abstract/media';
@import '../../../../../../../geely/src/style/abstract/button';
@import '../../../../../../../geely/src/style/abstract/loader';
@import '../../../../../../../geely/src/style/abstract/icons';
@import '../../../../../../../geely/src/style/abstract/parts';
@import '../../../../../../../geely/src/style/abstract/variables.override';
@import '../../../../../../../geely/src/style/abstract/button.override';
@import '../../../../../../../geely/src/style/abstract/media.override';
@import '../../../../../../../geely/src/style/abstract/parts.override';
@import '../../../../../../../geely/src/style/abstract/icons.override';

// a

.FlexibleFormsWidget {
    margin: 0;

    .Image_ratio_square {
        padding-block-end: 65%;
    }

    .Image-Image {
        object-fit: cover;
    }

    div {
        .Title {
            display: none;
        }
    }

    .Attr {
        &_Dotted {
            border-bottom: 1px dashed #999999;
            padding-block-end: 24px;
        }

        @include desktop {
            &_Col_2 {
                .Items {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
    
                    .Field-Wrapper {
                        width: 48%;
                        margin: 36px 0 0 0;
    
                        .Field {
                            margin: 0;
                        }
    
                        input {
                            width: 100%;
                        }
                    }
                }
            }

            &_Dotted {
                .Items {
                    .Field-Wrapper {
                        width: 48%;
                        margin: 36px 0 0 0;
    
                        .Field {
                            margin: 0;
                        }
    
                        input {
                            width: 100%;
                        }
                    }
                }
            }
        }

        &_HasTitle {
            .Title {
                display: block;
                margin-block-start: 40px;
            }
        }
    }

    .FlexibleForms {
        &-Skip {
            background-color: var(--secondary-blue-color);
            border-color: var(--secondary-blue-color);
           
            @include desktop {
                width: 300px;
                margin-inline-end: 20px;
            }

            @include mobile {
                margin-block-end: 20px;
                width: 100%;
            }
            
            &:hover {
                transition: 0.3s;
                background-color: var(--secondary-blue-color);
                border-color: var(--secondary-blue-color);
            }
        }

        &-Buttons {
            display: flex;

            @include desktop {
                justify-content: left;
            }

            @include mobile {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .Button {
                font-family: $font-HyundaiSansHeadKR;
                padding: 16px 20px;
                height: 60px;
                font-size: 22px;
                font-weight: 700;
                line-height: 28px;
                text-align: center;

                @include desktop {
                    width: 300px;
                    margin-inline-end: 20px;
                }

                @include mobile {
                    margin-block-end: 20px;
                    width: 100%;
                }
            }
        }
    }
}

.TradeinTooltipWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .TradeinTooltipText {
        color: black;

        @include desktop {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        @include mobile {
            font-family: $font-HyundaiSansHeadKR;
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
        }
    }

    .Image_ratio_square {
        @include mobile { 
            padding-block-end: 75%;
        }
    }
}
