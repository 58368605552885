/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-colon-space-after */
/* Custom properties */

:root {
    --tooltip-text-color: white;
    --tooltip-background-color: white;
    --tooltip-margin: 13px;
    --tooltip-arrow-size: 3px;
    --disclaimer-shadow: 5px 10px 15px 0px #00000014;
}

.ToolTip {
    &-Popup {
        @include mobile {
            &.Popup {
                .Popup {
                    &-Content {
                        inset-block-end: 0;
                        position: absolute;
                        width: 100%;
                        border-radius: 10px 10px 0 0 ;
                        padding: 0 16px 91px 16px;
                        overflow-y: unset;
                    }

                    &-TopWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        inset-block-start: 0;
                        width: 100%;
                        height: 30px;
                        margin-block-end: 52px;

                        .Rectangle {
                            width: 34px;
                            height: 4px;
                            background-image: url("data:image/svg+xml,%3Csvg width='34' height='4' viewBox='0 0 34 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='34' height='4' rx='2' fill='%23CCCCCC'/%3E%3C/svg%3E%0A");
                        }

                        .Image-Image {
                            height: 256px;
                            width: 382px;
                        }
                    }
                }
            }
        }

        @include desktop {
            &.Popup {
                &_isVisible {
                    width: unset;
                    height: unset;
                    position: absolute;
                    transform: translateY(-95%);
                    inset-inline-start: 20px;
                }

                z-index: 2;
                display: unset;
                background-color: transparent;

                .Popup {
                    transition: 0.3s;

                    &-Header {
                        padding: 10px 0;
                    }

                    &-CloseBtn {
                        .CloseIcon {
                            width: 10px;
                            height: 10px;
                            transform: scale(1.3);
                        }
                    }

                    &-Content {
                        box-shadow: var(--disclaimer-shadow);
                        border: 1px solid var(--stroke-color);
                        min-width: 270px;
                        padding: 16px;
                        overflow: visible;

                        &::before {
                            content: " ";
                            inset-inline-start: 4%;
                            border: solid transparent;
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-width: calc(var(--tooltip-arrow-size) + 1px);
                            margin-inline-start: calc((var(--tooltip-arrow-size) + 1px) * -1);

                            [dir="rtl"] & {
                                transform: rotateY(180deg);
                            }
                        }

                        &::after {
                            content: " ";
                            inset-inline-start: 4%;
                            border: solid transparent;
                            height: 0;
                            width: 0;
                            position: absolute;
                            pointer-events: none;
                            border-width: var(--tooltip-arrow-size);
                            margin-inline-start: calc(var(--tooltip-arrow-size) * -1);
                        }

                        &_direction {
                            &_top {
                                inset-block-start: calc(var(--tooltip-margin) * -1);

                                &::before {
                                    inset-block-start: 100%;
                                    border-top-color: var(--stroke-color);
                                    border-left-color: var(--stroke-color);
                                }

                                &::after {
                                    inset-block-start: 100%;
                                    border-top-color: var(--tooltip-background-color);
                                    border-left-color: var(--tooltip-background-color);
                                }
                            }

                            &_top_left {
                                inset-block-end: 14px;
                                inset-inline-end: 282px;

                                &::before {
                                    inset-block-start: 100%;
                                    inset-inline: unset 4%;
                                    border-top-color: var(--stroke-color);
                                    border-left-color: var(--stroke-color);
                                    transform: rotateY(180deg);

                                    [dir="rtl"] & {
                                        transform: rotateY(0);
                                    }
                                }

                                &::after {
                                    inset-block-start: 100%;
                                    border-top-color: var(--tooltip-background-color);
                                    border-left-color: var(--tooltip-background-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
