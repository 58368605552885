/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ForgotPassword {
    margin-block-start: var(--header-height);

    &-InnerWrapper {
        padding-block-start: var(--breadcrumbs-height);
        display: block;
        width: 100%;
        max-width: 560px;

        & > div {
            margin: 0;
        }
    }

    .MyAccountOverlay {
        &-Buttons {
            margin-block-start: 20px;
        }
    }

    &-WrapperHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            align-items: flex-end;
        }

        @include small-mobile {
            flex-direction: column;
            align-items: flex-start;
        }

        .RequiredMark {
            display: flex;
            justify-content: space-between;

            p {
                margin-block-end: 0;
                font-size: 14px;
                line-height: 20px;
            }

            span {
                padding-block-end: 0;
            }
        }
    }

    .ContentWrapper {
        padding-block: 24px 48px;
        padding-inline: 0;

        @include desktop {
            padding-block: 60px 108px;
        }

        @include mobile {
            padding-inline: 16px;
        }
    }
}
