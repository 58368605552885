$sliderGradient: linear-gradient(
    203deg,
    rgba(0, 0, 0, 0.2) 3.86%,
    rgba(0, 0, 0, 0.198271) 6.64%,
    rgba(0, 0, 0, 0.192898) 9.42%,
    rgba(0, 0, 0, 0.183668) 12.2%,
    rgba(0, 0, 0, 0.170518) 14.98%,
    rgba(0, 0, 0, 0.153645) 17.76%,
    rgba(0, 0, 0, 0.133623) 20.54%,
    rgba(0, 0, 0, 0.111462) 23.32%,
    rgba(0, 0, 0, 0.0885383) 26.1%,
    rgba(0, 0, 0, 0.0663768) 28.88%,
    rgba(0, 0, 0, 0.046355) 31.66%,
    rgba(0, 0, 0, 0.0294823) 34.44%,
    rgba(0, 0, 0, 0.016332) 37.22%,
    rgba(0, 0, 0, 0.007102) 40%,
    rgba(0, 0, 0, 0.00172944) 42.78%,
    rgba(0, 0, 0, 0) 45.56%
);

.Geely {
    &-HeroSliderContentWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;

        @include desktop {
            padding: 11vh 8vw;
        }

        @include mobile {
            padding: 8vh 5vw;
        }
    }

    &-HeroTextBlock {
        font-family: var(--font-family-sans-pro);
        font-weight: 500;
        color: white;

        @include desktop {
            text-align: start;
        }

        @include mobile {
            text-align: center;
            width: 100%;
        }
    }

    &-HeroHeader {
        @include desktop {
            font-size: 48px;
            line-height: 52px;
            margin-block-end: 3vh;
        }

        @include mobile {
            font-size: 32px;
            line-height: 38px;
            margin-block-end: 2vh;
            margin-inline: 2vw;
        }
    }

    &-HeroSubHeader {
        @include desktop {
            font-size: 24px;
            line-height: 32px;
            margin-block-end: 7vh;
        }

        @include mobile {
            font-size: 20px;
            line-height: 28px;
            margin-inline: 3vw;
        }
    }

    &-Button {
        background: var(--primary-base-color);
        border-radius: 999px;
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        justify-content: center;
        color: white;

        @include desktop {
            width: 165px;
        }

        @include mobile {
            width: 100%;
            position: absolute;
            inset-block-end: 20px;
        }
    }

    &-ArrowIcon {
        width: 16px;
        height: 16px;
        background-image: $arrowIcon;
        background-repeat: no-repeat;
        margin-inline-start: 12px;
    }

    &-HeroSliderLogo {
        @include mobile {
            display: none;
        }

        display: block;
        width: 160px;
        height: 92px;
        background-image: $geelyLogo;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.Geely-Slider {
    height: calc(100vh - var(--header-nav-height));
    width: 100vw;

    .RenderWhenVisible {
        height: 100%;

        .Image {
            &::after {
                content: '';
                position: absolute;
                inset-block: 0;
                background: $sliderGradient;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.geely-homepage-hero {
    height: 100%;

    .SliderWidget-FigureImage {
        background: rgba(0, 0, 0, 1);
    }

    .SliderWidget-Figcaption {
        inset-block-start: 0;
        height: 100%;
        transform: none;
    }

    img {
        opacity: 1;
    }

    .Slider-Crumbs {
        /* stylelint-disable declaration-no-important */
        position: absolute !important;
        z-index: 5;

        @include desktop {
            margin: 0 0 25px 0 !important;
        }

        @include mobile {
            margin: 0 0 15px 0 !important;
        }
    }

    .Slider-Crumb {
        background-color: white;

        @include desktop {
            width: 12px;
            height: 12px;
        }

        @include mobile {
            width: 10px;
            height: 10px;
        }

        &_isActive {
            background-color: var(--primary-base-color);

            @include desktop {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.HomePage {
    .CmsPage {
        &_isBreadcrumbsHidden {
            margin-block-start: 0;
        }

        &-Wrapper_page_width_default {
            margin-block-start: 0;
        }
    }
}
