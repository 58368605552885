/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.DealersOfferStep {
    &-ButtonBlock {
        @include mobile {
            width: 80%;
        }

        .Button {
            @include desktop {
                inset-block-start: 50%;
                transform: translateY(-50%);
            }
        }

        html[dir="rtl"] & {
            @include desktop {
                margin-inline-end: 8px;
            }
        }
    }

    &-BottomBlock {
        @include mobile {
            align-items: center;
        }
    }

    &-TotalInfoBlock {
        @include mobile {
            padding-inline-start: 20px;
            width: 100%;
        }
    }

    &-ModelBlock {
        border-bottom: none;
    }

    &-ProductAttributes {
        .ProductAttributes {
            &-Group {
                /* stylelint-disable-next-line declaration-no-important */
                margin-block-end: 12px !important;
            }

            &-AttributeHeader {
                margin-block-end: 4px;
            }
        }
    }
}
