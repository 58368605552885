/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.SpecificationsBlock {
    &-Wrapper {
        @include mobile {
            width: 80%;
        }

        h4 {
            font-family: var(--font-family-sans-pro);
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;

            @include desktop {
                margin-block-end: 8px;
            }

            @include mobile {
                margin-block-end: 10px;
            }
        }
    }

    &-ItemsWrapper {
        display: flex;

        @include mobile {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    &-Item {
        background-color: var(--default-secondary-white-color);
        height: 32px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline-end: 4px;
        padding: 6px 12px;

        @include desktop {
            flex: 1 1 auto;
            max-width: 487px;
        }

        @include mobile {
            flex: 1 0 25%;
            margin-block-end: 4px;
        }
    }

    &-TypeText {
        font-family: var(--font-family-sans-pro);
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--default-primary-silver-color);
    }

    &-TypeIcon {
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        margin-inline-end: 8px;

        &_icon_geely_fuel_type {
            background-image: $fuelIconS;
        }

        &_icon_geely_engine {
            background-image: $engineIconS;
        }

        &_icon_geely_transmission {
            background-image: $transmissionS;
        }

        &_icon_geely_traction {
            background-image: $tractionIconS;
        }
    }
}
